import React, { Component } from "react";
import { Accordion, Col, Form } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepSix extends Component {
  continue = (e) => {
    var defendent_sign_divorce_paper_yn = document.getElementById(
      "defendent_sign_divorce_paper_yn"
    );
    var required_span_defendent_sign_divorce_paper_yn = document.getElementById(
      "required_span_defendent_sign_divorce_paper_yn"
    );
    if (defendent_sign_divorce_paper_yn.value === "") {
      var att = document.createAttribute("required");
      defendent_sign_divorce_paper_yn.setAttributeNode(att);
      required_span_defendent_sign_divorce_paper_yn.style.display = "block";
      cogoToast.error("Please Select Defendant will sign the divorce papers?", {
        position: "top-center",
      });
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const { values, inputChange } = this.props;
    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8} sm={12}>
            <div className="container">
              <h4 className="mb-5">
                Will your spouse sign the divorce papers?
              </h4>
              <div className="form-group">
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "RED",
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "RED",
                    }}
                  >
                    {" "}
                    Required
                  </span>
                </p>
                <Form.Label className="required">
                  Defendant will sign the divorce papers?
                </Form.Label>
              </div>

              <div className="form-group">
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="defendent_sign_divorce_paper_yn"
                  name="defendent_sign_divorce_paper_yn"
                  value={values.defendent_sign_divorce_paper_yn}
                />{" "}
                <br />
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Yes"
                  id="defendent_sign_divorce_paper_yes"
                  name="defendent_sign_divorce_paper_yn"
                  label="Yes"
                  onChange={inputChange("defendent_sign_divorce_paper_yn")}
                />
                <b>Explanation:</b> Defendant must sign an Affidavit of
                Defendant.
                <br />
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="No"
                  id="defendent_sign_divorce_paper_no"
                  name="defendent_sign_divorce_paper_yn"
                  label="No"
                  onChange={inputChange("defendent_sign_divorce_paper_yn")}
                />
                <b>Explanation:</b> Defendant must be served with divorce
                papers. <br />
                <span style={{ paddingLeft: "60px" }}>
                  Additional information will be provided to you.
                </span>
                <br />
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Maybe"
                  id="defendent_sign_divorce_paper_maybe"
                  name="defendent_sign_divorce_paper_yn"
                  label="Maybe"
                  onChange={inputChange("defendent_sign_divorce_paper_yn")}
                />
                <span
                  id="required_span_defendent_sign_divorce_paper_yn"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select Defendant will sign the divorce papers?
                </span>
              </div>

              <br />
              <br />
              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepSix;
