import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";


class DivorceWithoutChildrenFormStepSuccess extends Component {
  render() {
    return (
      <div>
        <Container className="mt-lg-5 mb-5">
          <Row>
            <Col lg={3} md={3} sm={2}>
              &nbsp;
            </Col>
            <Col lg={6} md={6} sm={8}>
              <h1 className="text-white">Your Intake Created Successfully!</h1>
            </Col>
            <Col lg={3} md={3} sm={2}>
              &nbsp;
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepSuccess;
