import React, {Component, Fragment} from 'react';

class Services extends Component {
    render() {
        return (
            <Fragment>
                <div className="service-v1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h2>Our Services</h2>
                                    <p>We Are Here to Provide You Awesome Service Always</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url('./images/service-4.jpg')" }}>

                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Family Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam. eu aperiri definiebas concludaturque eam. </p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-5.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Medical Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam. eu aperiri definiebas concludaturque eam. </p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-6.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Property Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam. </p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-7.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Insurance Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam. </p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-8.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Education Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam.</p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-9.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Transportation Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam.</p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-10.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Trade Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam.</p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 s-item">
                                <div className="item">
                                    <div className="photo" style={{ backgroundImage: "url(./images/service-11.jpg)" }}>
                                    </div>
                                    <div className="text">
                                        <div className="inner">
                                            <h3><a href="">Criminal Law</a></h3>
                                            <p>Ad his diam eirmod persecuti. Eum health cube scriptorem eu, eu aperiri definiebas concludaturque eam.</p>
                                            <p className="button">
                                                <a href="">Read More &raquo;</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Services;