import React, { Component } from "react";
import Home from "../components/Home/Home";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Home />
      </div>
    );
  }
}

export default HomePage;
