import React, {Component, Fragment} from 'react';
import Slider from "react-slick";

class Attorneys extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <div className="attorney-v1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h2>Our Expert Attorneys</h2>
                                    <p>Meet with All Our Qualified Attorneys</p>
                                </div>
                                <Slider {...settings}>

                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-2.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-2.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-2.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-2.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-2.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-2.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage: "url('./images/attorney-1.jpg')" }}>
                                                </div>
                                                <div className="overlay">

                                                </div>
                                                <div className="social-icons">
                                                    <ul>
                                                        <li>
                                                            <a href="http://www.facebook.com" target="_blank">
                                                                <i className="fa fa-facebook"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.twitter.com" target="_blank">
                                                                <i className="fa fa-twitter"> </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="http://www.linkedin.com" target="_blank">
                                                                <i className="fa fa-linkedin"> </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Robert Smith</a>
                                                </h3>
                                                <p>Divorce Lawyer</p>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Attorneys;