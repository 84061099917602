import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepNine extends Component {
  continue = (e) => {
    var file_submit_county = document.getElementById("file_submit_county");
    var required_span_file_submit_county = document.getElementById(
      "required_span_file_submit_county"
    );
    if (file_submit_county.value === "") {
      var att = document.createAttribute("required");
      file_submit_county.setAttributeNode(att);
      required_span_file_submit_county.style.display = "block";
      cogoToast.error("Please Select File Submit County", {
        position: "top-center",
      });
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const { values, inputChange } = this.props;
    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8} sm={12}>
            <div className="container">
              <h4 className="mb-5">
                What COUNTY do you want to file your divorce in?
              </h4>
              <p>
                <b>Explanation:</b> Some Counties are less busy than others.
                Some Counties may process your divorce quicker. You may think it
                is a good idea to shop around and find the County with the
                fastest processing times. However, the Court may reject your
                divorce papers, If nether you nor your spouse reside in the
                County where you filed. We recommend to file in the County where
                you or your spouse currently resides but the choice is yours.
              </p>
              <p>
                How do I find the County with the fastest processing time for a
                divorce? Additional information will be provided to you.
              </p>
              <p>
                <span
                  style={{ fontWeight: "bold", fontSize: "20px", color: "RED" }}
                >
                  *
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px", color: "RED" }}
                >
                  {" "}
                  Required
                </span>
              </p>

              <div className="form-group">
                <label htmlFor="file_submit_county" className="required">
                  County:
                </label>
                <Form.Select
                  aria-label="File Submit County"
                  className="form-control"
                  id="file_submit_county"
                  name="file_submit_county"
                  onChange={inputChange("file_submit_county")}
                  value={values.file_submit_county}
                >
                  <option value="">Select County</option>
                  <option value="Albany County">Albany County</option>
                  <option value="Allegany County">Allegany County</option>
                  <option value="Bronx County">Bronx County</option>
                  <option value="Broome County">Broome County</option>
                  <option value="Cattaraugus County">Cattaraugus County</option>
                  <option value="Cayuga County">Cayuga County</option>
                  <option value="Chautauqua County">Chautauqua County</option>
                  <option value="Chemung County">Chemung County</option>
                  <option value="Chenango County">Chenango County</option>
                  <option value="Clinton County">Clinton County</option>
                  <option value="Columbia County">Columbia County</option>
                  <option value="Cortland County">Cortland County</option>
                  <option value="Delaware County">Delaware County</option>
                  <option value="Dutchess County">Dutchess County</option>
                  <option value="Erie County">Erie County</option>
                  <option value="Essex County">Essex County</option>
                  <option value="Franklin County">Franklin County</option>
                  <option value="Fulton County">Fulton County</option>
                  <option value="Genesee County">Genesee County</option>
                  <option value="Greene County">Greene County</option>
                  <option value="Hamilton County">Hamilton County</option>
                  <option value="Herkimer County">Herkimer County</option>
                  <option value="Jefferson County">Jefferson County</option>
                  <option value="Kings County (Brooklyn)">
                    Kings County (Brooklyn)
                  </option>
                  <option value="Lewis County">Lewis County</option>
                  <option value="Livingston County">Livingston County</option>
                  <option value="Madison County">Madison County</option>
                  <option value="Monroe County">Monroe County</option>
                  <option value="Montgomery County">Montgomery County</option>
                  <option value="Nassau County">Nassau County</option>
                  <option value="New York County (Manhattan)">
                    New York County (Manhattan)
                  </option>
                  <option value="Niagara County">Niagara County</option>
                  <option value="Oneida County">Oneida County</option>
                  <option value="Onondaga County">Onondaga County</option>
                  <option value="Ontario County">Ontario County</option>
                  <option value="Orange County">Orange County</option>
                  <option value="Orleans County">Orleans County</option>
                  <option value="Oswego County">Oswego County</option>
                  <option value="Otsego County">Otsego County</option>
                  <option value="Putnam County">Putnam County</option>
                  <option value="Queens County">Queens County</option>
                  <option value="Rensselaer County">Rensselaer County</option>
                  <option value="Richmond County (Staten Island)">
                    Richmond County (Staten Island)
                  </option>
                  <option value="Rockland County">Rockland County</option>
                  <option value="Saint Lawrence County">
                    Saint Lawrence County
                  </option>
                  <option value="Saratoga County">Saratoga County</option>
                  <option value="Schenectady County">Schenectady County</option>
                  <option value="Schoharie County">Schoharie County</option>
                  <option value="Schuyler County">Schuyler County</option>
                  <option value="Seneca County">Seneca County</option>
                  <option value="Steuben County">Steuben County</option>
                  <option value="Suffolk County">Suffolk County</option>
                  <option value="Sullivan County">Sullivan County</option>
                  <option value="Tioga County">Tioga County</option>
                  <option value="Tompkins County">Tompkins County</option>
                  <option value="Ulster County">Ulster County</option>
                  <option value="Warren County">Warren County</option>
                  <option value="Washington County">Washington County</option>
                  <option value="Wayne County">Wayne County</option>
                  <option value="Westchester County">Westchester County</option>
                  <option value="Wyoming County">Wyoming County</option>
                  <option value="Yates County">Yates County</option>
                </Form.Select>
                <span
                  id="required_span_file_submit_county"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select County
                </span>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepNine;
