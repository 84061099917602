import React from "react";
import IntakePage from "./pages/IntakePage";
import TopNavigation from "./components/TopNavigation/TopNavigation";
import TopBanner from "./components/TopBanner/TopBanner";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage";
import AppRoute from "./router/AppRoute";

function App() {
  return (
    <HashRouter>
      <AppRoute />
    </HashRouter>
  );
}

export default App;
