import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";

class DivorceWithoutChildrenFormStepTwelve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maint_start_date: "",
      maint_end_date: "",
    };
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleStartDateSubmit = this.handleStartDateSubmit.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleEndDateSubmit = this.handleEndDateSubmit.bind(this);
  }

  handleStartDate(date) {
    this.setState({
      maint_start_date: date,
    });
  }
  handleEndDate(date) {
    this.setState({
      maint_end_date: date,
    });
  }

  handleStartDateSubmit(e) {
    e.preventDefault();
    let Maintenance_Start_Date = this.state.maint_start_date;
    console.log(Maintenance_Start_Date.format("L"));
  }

  handleEndDateSubmit(e) {
    e.preventDefault();
    let Maintenance_End_Date = this.state.maint_end_date;
    console.log(Maintenance_End_Date.format("L"));
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  showOptionDiv() {
    if (document.getElementById("pay_receive_spousal_yes").checked === true) {
      document.getElementById(
        "pay_receive_spousal_maintenance_alimony_option_section"
      ).style.display = "block";
    }
  }

  hideOptionDiv() {
    if (document.getElementById("pay_receive_spousal_no").checked === true) {
      document.getElementById(
        "pay_receive_spousal_maintenance_alimony_option_section"
      ).style.display = "none";
    }
  }

  render() {
    const { values, inputChange } = this.props;
    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={1} md={1}></Col>
          <Col lg={10} md={10} sm={12}>
            <div className="container">
              <h4 className="mb-5">Spousal Maintenance (Alimony):</h4>
              <p style={{ textAlign: "justify" }}>
                <b>Warning:</b> under the Maintenance Guidelines Law ([S.
                5678/A. 7645], Chapter 269, Laws of 2015), you should be aware
                that you may be obligated to pay or entitled to receive spousal
                maintenance for the amounts and length of time indicated in your
                annual income worksheet, form UD-8(1), and your maintenance
                guidelines worksheet, form UD-8(2).
              </p>
              <p style={{ textAlign: "justify" }}>
                <b>Explanation: What is Spousal Maintenance (Alimony)?</b>
              </p>
              <p style={{ textAlign: "justify" }}>
                It is the support that you or your spouse may be entitled. The
                length of support depends on. The length of your marriage. The
                amount of support depends on the difference between your or your
                spouse’s annual incomes. This support is to help you or your
                spouse to get back on her/his feet after a divorce.
              </p>
              <p style={{ textAlign: "justify" }}>
                For example, if a woman was a homemaker during the marriage and
                sacrificed her career to raise the children, she may need time
                to find employment that would support her after a divorce. Hence
                the need for spousal maintenance (alimony). In contrast, if both
                spouses are self-supporting, there may be no need for spousal
                maintenance (alimony).
              </p>
              <p style={{ textAlign: "justify" }}>
                <b>Explanation:</b> Is Spousal Maintenance (Alimony) mandatory?
              </p>
              <p style={{ textAlign: "justify" }}>
                No. The parties may agree to waive support.
              </p>
              <p style={{ textAlign: "justify" }}>
                <b>Explanation:</b> What if we cannot agree?
              </p>
              <p style={{ textAlign: "justify" }}>
                If you choose Option 1 above, we will calculate the guideline
                amount of spousal maintenance (alimony) and the length of time
                the maintenance should be paid.
              </p>
              <p style={{ textAlign: "justify" }}>
                However, if you and your spouse agree to a different length of
                time and amount of maintenance (alimony) than the guideline
                length of time and amount, please choose Option 2 above. We will
                incorporate your agreement into your Stipulation of Settlement
                (divorce agreement).
              </p>
              <p style={{ textAlign: "justify" }}>
                If you request to pay or receive post-divorce maintenance
                (alimony) and your spouse refuses to sign the divorce documents
                because you disagree on the length of time or amount of spousal
                maintenance (alimony) to be paid, you will have options.
              </p>
              <p style={{ textAlign: "justify" }}>
                <ol>
                  <li>
                    You could either try to renegotiate and agree to a different
                    length of time or amount of spousal maintenance (alimony) to
                    be paid or you could agree there would be no spousal
                    maintenance (alimony) paid, if that is what your spouse
                    wants. <b>OR</b>
                  </li>
                  <li>
                    You could request a judicial intervention, which would mean
                    going to Court to speak to a Judge either in person or
                    virtually via Microsoft Teams. If you choose this option,
                    your divorce will become contested, and we recommend that
                    you hire a lawyer. You may be ordered to show proof of your
                    income amounts at a court hearing.
                  </li>
                </ol>
              </p>
              <p>
                <span
                  style={{ fontWeight: "bold", fontSize: "20px", color: "RED" }}
                >
                  *
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px", color: "RED" }}
                >
                  {" "}
                  Required
                </span>
              </p>

              <div className="form-group">
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="pay_receive_spousal"
                  name="pay_receive_spousal"
                  value={values.pay_receive_spousal}
                />{" "}
                <br />
                <Form.Label className="required">
                  Pay or Receive Spousal Maintenance (Alimony)?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Yes"
                  id="pay_receive_spousal_yes"
                  name="pay_receive_spousal"
                  label="Yes"
                  onChange={inputChange("pay_receive_spousal")}
                  onClick={this.showOptionDiv}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="No"
                  id="pay_receive_spousal_no"
                  name="pay_receive_spousal"
                  label="No"
                  onChange={inputChange("pay_receive_spousal")}
                  onClick={this.hideOptionDiv}
                />
              </div>

              <div
                className="form-group"
                style={{ display: "none" }}
                id="pay_receive_spousal_maintenance_alimony_option_section"
              >
                <Form.Label className="required">Select An Option</Form.Label>

                <div className="form-group">
                  <input
                    type="hidden"
                    className="radio-buttons"
                    id="pay_recv_spous_maint_opt"
                    name="pay_recv_spous_maint_opt"
                    value={values.pay_recv_spous_maint_opt}
                  />{" "}
                  <br />
                  <Form.Check
                    style={{ color: "#ff531a", fontWeight: "bold" }}
                    type="radio"
                    value="Option One"
                    id="pay_receive_spousal_maintenance_alimony_option_pay"
                    name="pay_recv_spous_maint_opt"
                    label="Option 1 : State Guideline Spousal Maintenance"
                    onChange={inputChange("pay_recv_spous_maint_opt")}
                  />
                  <br />
                  <Form.Text className="text-muted">
                    Do you want to PAY or RECEIVE Spousal Maintenance?
                  </Form.Text>
                  <input
                    type="hidden"
                    className="radio-buttons"
                    id="pay_recv_spous_maint_opt_one"
                    name="pay_recv_spous_maint_opt_one"
                    value={values.pay_recv_spous_maint_opt_one}
                  />{" "}
                  <br />
                  <Form.Check
                    inline
                    type="radio"
                    value="Pay"
                    id="pay_receive_spousal_maintenance_option_one_yes"
                    name="pay_recv_spous_maint_opt_one"
                    label="Pay"
                    onChange={inputChange("pay_recv_spous_maint_opt_one")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    value="Receive"
                    id="pay_receive_spousal_maintenance_option_one_no"
                    name="pay_recv_spous_maint_opt_one"
                    label="Receive"
                    onChange={inputChange("pay_recv_spous_maint_opt_one")}
                  />
                  <br />
                  <div
                    id="state_guideline_spousal_maintenance"
                    className="form-group"
                  >
                    <p style={{ textAlign: "justify" }}>
                      <b>Explanation: </b> What is Support Collection Unit
                      (SCU)?
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      It is an agency that collects spousal maintenance
                      (alimony) and child support. SCU keeps detailed records of
                      all payments made. If the payments are not being made, SCU
                      has power to request a suspension of the payor’s driver’s
                      license. SCU can request that an employer of the payor
                      deducts the amount of court ordered spousal maintenance
                      (alimony) and child support from the payor’s paycheck. SCU
                      is a great resource for unrepresented parties who are
                      worried about their spousal maintenance (alimony) and
                      child support being paid on time.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      We recommend that you take advantage of the SCU services.
                      They are free.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      If want to apply for SCU services, we will provide you
                      with additional information on how to do it. Please note
                      that if you apply for SCU services, there could be a 3 to
                      6 weeks delay before you receive your first payment of
                      spousal maintenance (alimony) and child support from SCU.
                    </p>

                    <div className="form-group">
                      <label
                        htmlFor="maint_pd_dt_pd_sup_colct_opt_one"
                        className="required"
                      >
                        Maintenance paid directly OR paid through the Support
                        Collection Unit?
                      </label>
                      <Form.Select
                        aria-label="Maintenance paid directly OR Paid through the Support Collection Unit"
                        className="form-control"
                        name="maint_pd_dt_pd_sup_colct_opt_one"
                        style={{ width: "300px" }}
                        onChange={inputChange(
                          "maint_pd_dt_pd_sup_colct_opt_one"
                        )}
                        value={values.maint_pd_dt_pd_sup_colct_opt_one}
                      >
                        <option value="">Select Payment Method</option>
                        <option value="Maintenance Paid Directly">
                          Maintenance Paid Directly
                        </option>
                        <option value="Pay Via Support Collection Unit (SCU)">
                          Pay Via Support Collection Unit (SCU)
                        </option>
                      </Form.Select>
                    </div>
                  </div>
                  <br />
                  <br />
                  <Form.Check
                    style={{ color: "#ff531a", fontWeight: "bold" }}
                    type="radio"
                    value="Option Two"
                    id="pay_receive_spousal_maintenance_alimony_option_receive"
                    name="pay_recv_spous_maint_opt"
                    label="Option 2 : Your Own Spousal Maintenance Agreement"
                    onChange={inputChange("pay_recv_spous_maint_opt")}
                  />
                  <br />
                  <Form.Text className="">
                    Do you want to PAY or RECEIVE Spousal Maintenance?
                  </Form.Text>
                  <input
                    type="hidden"
                    className="radio-buttons"
                    id="pay_recv_spous_maint_opt_two"
                    name="pay_recv_spous_maint_opt_two"
                    value={values.pay_recv_spous_maint_opt_two}
                  />{" "}
                  <br />
                  <Form.Check
                    inline
                    type="radio"
                    value="Pay"
                    id="pay_receive_spousal_maintenance_option_two_yes"
                    name="pay_recv_spous_maint_opt_two"
                    label="Pay"
                    onChange={inputChange("pay_recv_spous_maint_opt_two")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    value="Receive"
                    id="pay_receive_spousal_maintenance_option_two_no"
                    name="pay_recv_spous_maint_opt_two"
                    label="Receive"
                    onChange={inputChange("pay_recv_spous_maint_opt_two")}
                  />
                  <div className="form-group">
                    <label
                      htmlFor="How much Maintenance per month?"
                      className="required"
                    >
                      How much Maintenance per month?
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "300px" }}
                      name="how_much_maint_per_month"
                      onChange={inputChange("how_much_maint_per_month")}
                      value={values.how_much_maint_per_month}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label
                      htmlFor="Maintenance Start Date?"
                      className="required"
                    >
                      Maintenance Start Date?
                    </label>

                    <DatePicker
                      className="date_custom"
                      id="maint_start_date"
                      name="maint_start_date"
                      placeholderText="DD-MM-YYYY"
                      selected={this.state.maint_start_date}
                      onChange={this.handleStartDate}
                      onClickOutside={
                        (values.maint_start_date = this.datePickerValue)
                      }
                      maxDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      showMonthDropdown
                      style={{ width: "300px" }}
                      value={(values.maint_start_date = this.datePickerValue)}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="Maintenance End Date?" className="required">
                      Maintenance End Date?
                    </label>
                    <DatePicker
                      className="date_custom"
                      id="maint_end_date"
                      name="maint_end_date"
                      placeholderText="DD-MM-YYYY"
                      selected={this.state.maint_end_date}
                      onChange={this.handleEndDate}
                      onClickOutside={this.datePickerValue}
                      maxDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      showMonthDropdown
                      style={{ width: "300px" }}
                      value={(values.maint_end_date = this.datePickerValue)}
                    />
                  </div>
                  <br />
                  <div
                    id="your_own_spousal_maintenance_agreement"
                    className="form-group"
                  >
                    <p style={{ textAlign: "justify" }}>
                      <b>Explanation: </b> What is a reasonable length and
                      amount of spousal maintenance (alimony) after a divorce?
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Click here to use an Online{" "}
                      <a
                        className="url_link"
                        href="https://qfs.formsquo.com/FormsViewer/View?SPHostUrl=https%3A%2F%2Fnycourts.sharepoint.com%2Fsites%2FMatrimCalc&SPLanguage=en-US&SPClientTag=1&SPProductNumber=16.0.8613.1223&SPAppWebUrl=https%3A%2F%2Fnycourts-1cee1e08ae8e41.sharepoint.com%2Fsites%2FMatrimCalc%2FFormsViewer&templateName=MatrimonialCalculator&AppOnly=true"
                      >
                        Calculator
                      </a>{" "}
                      for both Guideline Maintenance and Child Support.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Click here to download a{" "}
                      <a
                        className="url_link"
                        href="/files/NoticeGuidelineMaintenance.pdf"
                      >
                        Notice of Guideline Maintenance
                      </a>{" "}
                      to see the formula behind the online calculator that the
                      Court would use to calculate the length of time and amount
                      of spousal maintenance (Alimony).
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      <b>Explanation: </b> What is Support Collection Unit
                      (SCU)?
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      It is an agency that collects spousal maintenance
                      (alimony) and child support. SCU keeps detailed records of
                      all payments made. If the payments are not being made, SCU
                      has power to request a suspension of the payor’s driver’s
                      license. SCU can request that an employer of the payor
                      deducts the amount of court ordered spousal maintenance
                      (alimony) and child support from the payor’s paycheck. SCU
                      is a great resource for unrepresented parties who are
                      worried about their spousal maintenance (alimony) and
                      child support being paid on time.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      We recommend that you take advantage of the SCU services.
                      They are free.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      If want to apply for SCU services, we will provide you
                      with additional information on how to do it. Please note
                      that if you apply for SCU services, there could be a 3 to
                      6 weeks delay before you receive your first payment of
                      spousal maintenance (alimony) and child support from SCU.
                    </p>

                    <div className="form-group">
                      <label
                        htmlFor="maint_pd_dt_pd_sup_colct_opt_two"
                        className="required"
                      >
                        Maintenance paid directly OR paid through the Support
                        Collection Unit?
                      </label>
                      <Form.Select
                        aria-label="Maintenance paid directly OR Paid through the Support Collection Unit"
                        className="form-control"
                        name="maint_pd_dt_pd_sup_colct_opt_two"
                        style={{ width: "300px" }}
                        onChange={inputChange(
                          "maint_pd_dt_pd_sup_colct_opt_two"
                        )}
                        value={values.maint_pd_dt_pd_sup_colct_opt_two}
                      >
                        <option value="">Select Payment Method</option>
                        <option value="Maintenance Paid Directly">
                          Maintenance Paid Directly
                        </option>
                        <option value="Pay Via Support Collection Unit (SCU)">
                          Pay Via Support Collection Unit (SCU)
                        </option>
                      </Form.Select>
                    </div>
                    <br />
                  </div>
                </div>
                <br />
              </div>

              <br />

              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={1} md={1}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepTwelve;
