import React, { Component, Fragment } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from "react-bootstrap";
import DivorceWithoutChildrenFormStepOne from "./DivorceWithoutChildrenFormStepOne";
import DivorceWithoutChildrenFormStepTwo from "./DivorceWithoutChildrenFormStepTwo";
import DivorceWithoutChildrenFormStepThree from "./DivorceWithoutChildrenFormStepThree";
import DivorceWithoutChildrenFormStepFour from "./DivorceWithoutChildrenFormStepFour";
import DivorceWithoutChildrenFormStepFive from "./DivorceWithoutChildrenFormStepFive";
import DivorceWithoutChildrenFormStepSix from "./DivorceWithoutChildrenFormStepSix";
import DivorceWithoutChildrenFormStepSeven from "./DivorceWithoutChildrenFormStepSeven";
import DivorceWithoutChildrenFormStepEight from "./DivorceWithoutChildrenFormStepEight";
import DivorceWithoutChildrenFormStepNine from "./DivorceWithoutChildrenFormStepNine";
import DivorceWithoutChildrenFormStepTen from "./DivorceWithoutChildrenFormStepTen";
import DivorceWithoutChildrenFormStepEleven from "./DivorceWithoutChildrenFormStepEleven";
import DivorceWithoutChildrenFormStepTwelve from "./DivorceWithoutChildrenFormStepTwelve";
import DivorceWithoutChildrenFormConfirm from "./DivorceWithoutChildrenFormConfirm";
import TopNavigation from "../TopNavigation/TopNavigation";
import TopBanner from "../TopBanner/TopBanner";
import Intake from "../Intake/Intake";
import Footer from "../Footer/Footer";


import DivorceWithoutChildrenFormStepSuccess from "./DivorceWithoutChildrenFormStepSuccess";

class DivorceWithoutChildrenForm extends Component {
  state = {
    step: 1,

    // One
    form_prefix: "GD",
    form_name: "Divorce Without Children",
    group_one: false,
    group_two: false,
    group_three: false,
    group_four: false,
    group_five: false,

    // Two
    name: "",
    email: "",
    mobile: "",
    password: "",

    // Three
    plaintiff_first_name: "",
    plaintiff_last_name: "",
    plaintiff_maiden_name: "",
    plaintiff_email: "",
    plaintiff_phone: "",
    plaintiff_street_address: "",
    plaintiff_city: "",
    plaintiff_state: "",
    plaintiff_zip: "",
    plaintiff_county: "",
    plaintiff_country: "",

    // Four
    plaintiff_date_of_birth: "",
    plaintiff_state_birth: "",
    plaintiff_county_birth: "",
    plaintiff_country_birth: "",
    plaintiff_social_security: "",
    plaintiff_social_sec_tax_has: "",
    plaintiff_social_sec_tax_hasnot: "",
    plaintiff_gross_annual_income: "",
    plaintiff_ny_city_yonkers_tax: "",

    // Five
    defendant_is_in_the_military: "",

    // Six
    defendent_sign_divorce_paper_yn: "",

    // Seven
    defendent_male_female: "",
    defendant_first_name: "",
    defendant_last_name: "",
    defendant_maiden_name: "",
    defendant_email: "",
    defendant_phone: "",
    defendant_street_address: "",
    defendant_city: "",
    defendant_state: "",
    defendant_zip: "",
    defendant_county_residence: "",
    defendant_country_residence: "",

    // Eight
    defendant_date_of_birth: "",
    defendant_state_of_birth: "",
    defendant_county_of_birth: "",
    defendant_country_of_birth: "",
    defendant_social_security: "",
    defendant_social_sec_tax_has: "",
    defendant_social_sec_tax_hasnot: "",
    defendant_gross_annual_income: "",
    defendant_ny_city_yonkers_tax: "",

    // Nine
    file_submit_county: "",

    // Ten
    type_wedding_ceremony: "",
    city_married_party: "",
    state_married_party: "",
    country_married_party: "",
    date_of_marriage: "",

    // Eleven
    complete_assets_debts_workbook_yes_no: "",
    want_settlement_yes_no: "",
    already_separation_yes_no: "",

    // Twelve
    pay_receive_spousal: "",
    pay_recv_spous_maint_opt: "",
    pay_recv_spous_maint_opt_one: "",
    maint_pd_dt_pd_sup_colct_opt_one: "",
    pay_recv_spous_maint_opt_two: "",
    how_much_maint_per_month: "",
    maint_start_date: "",
    maint_end_date: "",
    maint_pd_dt_pd_sup_colct_opt_two: "",
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  inputChangeGroup1 = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
      isChecked: !this.state.isChecked,
      group_one: !this.state.group_one,
    });
  };

  inputChangeGroup2 = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
      isChecked: !this.state.isChecked,
      group_two: !this.state.group_two,
    });
  };

  inputChangeGroup3 = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
      isChecked: !this.state.isChecked,
      group_three: !this.state.group_three,
    });
  };

  inputChangeGroup4 = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
      isChecked: !this.state.isChecked,
      group_four: !this.state.group_four,
    });
  };

  inputChangeGroup5 = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
      isChecked: !this.state.isChecked,
      group_five: !this.state.group_five,
    });
  };

  inputChangeChecked = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
      isChecked: !this.state.isChecked,
      group_one: !this.state.group_one,
    });
  };

  render() {
    const { step } = this.state;
    const {
      form_prefix,
      form_name,
      group_one,
      group_two,
      group_three,
      group_four,
      group_five,
      name,
      email,
      mobile,
      password,
      plaintiff_first_name,
      plaintiff_last_name,
      plaintiff_maiden_name,
      plaintiff_email,
      plaintiff_phone,
      plaintiff_street_address,
      plaintiff_city,
      plaintiff_state,
      plaintiff_zip,
      plaintiff_county,
      plaintiff_country,
      plaintiff_date_of_birth,
      plaintiff_state_birth,
      plaintiff_county_birth,
      plaintiff_country_birth,
      plaintiff_social_security,
      plaintiff_social_sec_tax_has,
      plaintiff_social_sec_tax_hasnot,
      plaintiff_gross_annual_income,
      plaintiff_ny_city_yonkers_tax,
      defendant_is_in_the_military,
      defendent_sign_divorce_paper_yn,
      defendent_male_female,
      defendant_first_name,
      defendant_last_name,
      defendant_maiden_name,
      defendant_email,
      defendant_phone,
      defendant_street_address,
      defendant_city,
      defendant_state,
      defendant_zip,
      defendant_county_residence,
      defendant_country_residence,
      defendant_date_of_birth,
      defendant_state_of_birth,
      defendant_county_of_birth,
      defendant_country_of_birth,
      defendant_social_security,
      defendant_social_sec_tax_has,
      defendant_social_sec_tax_hasnot,
      defendant_gross_annual_income,
      defendant_ny_city_yonkers_tax,
      file_submit_county,
      type_wedding_ceremony,
      city_married_party,
      state_married_party,
      country_married_party,
      date_of_marriage,
      complete_assets_debts_workbook_yes_no,
      want_settlement_yes_no,
      already_separation_yes_no,
      pay_receive_spousal,
      pay_recv_spous_maint_opt,
      pay_recv_spous_maint_opt_one,
      maint_pd_dt_pd_sup_colct_opt_one,
      pay_recv_spous_maint_opt_two,
      how_much_maint_per_month,
      maint_start_date,
      maint_end_date,
      maint_pd_dt_pd_sup_colct_opt_two,
    } = this.state;
    const values = {
      form_prefix,
      form_name,
      group_one,
      group_two,
      group_three,
      group_four,
      group_five,
      name,
      email,
      mobile,
      password,
      plaintiff_first_name,
      plaintiff_last_name,
      plaintiff_maiden_name,
      plaintiff_email,
      plaintiff_phone,
      plaintiff_street_address,
      plaintiff_city,
      plaintiff_state,
      plaintiff_zip,
      plaintiff_county,
      plaintiff_country,
      plaintiff_date_of_birth,
      plaintiff_state_birth,
      plaintiff_county_birth,
      plaintiff_country_birth,
      plaintiff_social_security,
      plaintiff_social_sec_tax_has,
      plaintiff_social_sec_tax_hasnot,
      plaintiff_gross_annual_income,
      plaintiff_ny_city_yonkers_tax,
      defendant_is_in_the_military,
      defendent_sign_divorce_paper_yn,
      defendent_male_female,
      defendant_first_name,
      defendant_last_name,
      defendant_maiden_name,
      defendant_email,
      defendant_phone,
      defendant_street_address,
      defendant_city,
      defendant_state,
      defendant_zip,
      defendant_county_residence,
      defendant_country_residence,
      defendant_date_of_birth,
      defendant_state_of_birth,
      defendant_county_of_birth,
      defendant_country_of_birth,
      defendant_social_security,
      defendant_social_sec_tax_has,
      defendant_social_sec_tax_hasnot,
      defendant_gross_annual_income,
      defendant_ny_city_yonkers_tax,
      file_submit_county,
      type_wedding_ceremony,
      city_married_party,
      state_married_party,
      country_married_party,
      date_of_marriage,
      complete_assets_debts_workbook_yes_no,
      want_settlement_yes_no,
      already_separation_yes_no,
      pay_receive_spousal,
      pay_recv_spous_maint_opt,
      pay_recv_spous_maint_opt_one,
      maint_pd_dt_pd_sup_colct_opt_one,
      pay_recv_spous_maint_opt_two,
      how_much_maint_per_month,
      maint_start_date,
      maint_end_date,
      maint_pd_dt_pd_sup_colct_opt_two,
    };

    switch (step) {
      case 1:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={5} key={1} />{" "}
                    &nbsp; <b>Step 1</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepOne
              nextStep={this.nextStep}
              inputChange={this.inputChange}
              inputChangeGroup1={this.inputChangeGroup1}
              inputChangeGroup2={this.inputChangeGroup2}
              inputChangeGroup3={this.inputChangeGroup3}
              inputChangeGroup4={this.inputChangeGroup4}
              inputChangeGroup5={this.inputChangeGroup5}
              values={values}
            />
            <Footer />
          </div>
        );

      case 2:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={10} key={2} />{" "}
                    &nbsp; <b>Step 2</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepTwo
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 3:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={15} key={3} />{" "}
                    &nbsp; <b>Step 3</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepThree
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 4:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={20} key={4} />{" "}
                    &nbsp; <b>Step 4</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepFour
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 5:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={25} key={5} />{" "}
                    &nbsp; <b>Step 5</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepFive
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 6:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={30} key={6} />{" "}
                    &nbsp; <b>Step 6</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepSix
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 7:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={35} key={7} />{" "}
                    &nbsp; <b>Step 7</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepSeven
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              inputChangeChecked={this.inputChangeChecked}
              values={values}
            />
            <Footer />
          </div>
        );

      case 8:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={40} key={8} />{" "}
                    &nbsp; <b>Step 8</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepEight
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 9:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={45} key={9} />{" "}
                    &nbsp; <b>Step 9</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepNine
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 10:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={55} key={10} />{" "}
                    &nbsp; <b>Step 10</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepTen
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 11:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={65} key={11} />{" "}
                    &nbsp; <b>Step 11</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepEleven
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 12:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={70} key={12} />{" "}
                    &nbsp; <b>Step 12</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormStepTwelve
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              inputChange={this.inputChange}
              values={values}
            />
            <Footer />
          </div>
        );

      case 13:
        return (
          <div>
            <TopNavigation />
            <TopBanner />
            <br />
            <br />
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <ProgressBar>
                    <ProgressBar animated variant="orange" now={80} key={13} />{" "}
                    &nbsp; <b>Step Confirm</b>
                  </ProgressBar>
                </Col>
              </Row>
            </Container>
            <DivorceWithoutChildrenFormConfirm
              prevStep={this.prevStep}
              values={values}
            />
            <Footer />
          </div>
        );
    }
  }
}

export default DivorceWithoutChildrenForm;
