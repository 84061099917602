import React, { Component, useState } from "react";
import { Col, Form } from "react-bootstrap";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plaintiff_date_birth: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(date) {
    this.setState({
      plaintiff_date_of_birth: date,
      placeholderText: "DD-MM-YYYY",
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let main = this.state.plaintiff_date_of_birth;
    console.log(main.format("L"));
  }

  continue = (e) => {
    var plaintiff_date_of_birth = document.getElementById(
      "plaintiff_date_of_birth"
    );
    var plaintiff_state_birth = document.getElementById(
      "plaintiff_state_birth"
    );
    var plaintiff_county_birth = document.getElementById(
      "plaintiff_county_birth"
    );
    var plaintiff_country_birth = document.getElementById(
      "plaintiff_country_birth"
    );
    var plaintiff_gross_annual_income = document.getElementById(
      "plaintiff_gross_annual_income"
    );

    var required_span_plaintiff_date_of_birth = document.getElementById(
      "required_span_plaintiff_date_of_birth"
    );
    var required_span_plaintiff_state_birth = document.getElementById(
      "required_span_plaintiff_state_birth"
    );
    var required_span_plaintiff_county_birth = document.getElementById(
      "required_span_plaintiff_county_birth"
    );
    var required_span_plaintiff_country_birth = document.getElementById(
      "required_span_plaintiff_country_birth"
    );
    var required_span_plaintiff_gross_annual_income = document.getElementById(
      "required_span_plaintiff_gross_annual_income"
    );

    if (plaintiff_date_of_birth.value === "") {
      var att = document.createAttribute("required");
      plaintiff_date_of_birth.setAttributeNode(att);
      required_span_plaintiff_date_of_birth.style.display = "block";
      cogoToast.error("Please Put Plaintiff Date of Birth", {
        position: "top-center",
      });
    } else if (plaintiff_state_birth.value === "") {
      var att = document.createAttribute("required");
      plaintiff_state_birth.setAttributeNode(att);
      required_span_plaintiff_date_of_birth.style.display = "none";
      required_span_plaintiff_state_birth.style.display = "block";
      cogoToast.error("Please Put Plaintiff State Birth", {
        position: "top-center",
      });
    } else if (plaintiff_county_birth.value === "") {
      var att = document.createAttribute("required");
      plaintiff_county_birth.setAttributeNode(att);
      required_span_plaintiff_state_birth.style.display = "none";
      required_span_plaintiff_county_birth.style.display = "block";
      cogoToast.error("Please Put Plaintiff County Birth", {
        position: "top-center",
      });
    } else if (plaintiff_country_birth.value === "") {
      var att = document.createAttribute("required");
      plaintiff_country_birth.setAttributeNode(att);
      required_span_plaintiff_county_birth.style.display = "none";
      required_span_plaintiff_country_birth.style.display = "block";
      cogoToast.error("Please Put Plaintiff Country Birth ", {
        position: "top-center",
      });
    } else if (plaintiff_gross_annual_income.value === "") {
      var att = document.createAttribute("required");
      plaintiff_gross_annual_income.setAttributeNode(att);
      required_span_plaintiff_country_birth.style.display = "none";
      required_span_plaintiff_gross_annual_income.style.display = "block";
      cogoToast.error("Please Put Plaintiff Country Birth ", {
        position: "top-center",
      });
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  showSocialSecurityTaxIDInputBox() {
    if (
      document.getElementById("plaintiff_social_security_yes").checked === true
    ) {
      document.getElementById(
        "social_security_tax_id_input_box_have_not_because"
      ).style.display = "none";
      document.getElementById(
        "social_security_tax_id_input_box"
      ).style.display = "block";
    }
  }
  showSocialSecurityTaxIDInputBoxHaveNotBecause() {
    if (
      document.getElementById("plaintiff_social_security_no").checked === true
    ) {
      document.getElementById(
        "social_security_tax_id_input_box"
      ).style.display = "none";
      document.getElementById(
        "social_security_tax_id_input_box_have_not_because"
      ).style.display = "block";
    }
  }

  componentDidMount() {
    window.scroll(0, 300);
  }

  handleDateChange = (date) => {
    let selectedDateFromCalender = date.toUTCString();
    this.setState({
      actualStartDate: selectedDateFromCalender,
    });
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8} sm={12}>
            <div className="container">
              <h4 className="mb-5">
                ADDITIONAL INFORMATION ABOUT THE PLAINTIFF:
              </h4>
              <p>
                <b>Security & Privacy:</b> This is a secured website. We keep
                your personal information confidential. We do not share your
                information with third parties. We do sell your information.
              </p>
              <p>
                <span
                  style={{ fontWeight: "bold", fontSize: "20px", color: "RED" }}
                >
                  *
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px", color: "RED" }}
                >
                  {" "}
                  Required
                </span>
              </p>

              <br />
              <div className="form-group">
                <label htmlFor="plaintiff_date_of_birth" className="required">
                  Date of Birth:
                </label>

                {/*<DatePicker*/}
                {/*className="form-control"*/}
                {/*id="plaintiff_date_of_birth"*/}
                {/*name="plaintiff_date_of_birth"*/}
                {/*placeholderText="DD-MM-YYYY"*/}
                {/*selected={this.state.plaintiff_date_of_birth}*/}
                {/*onChange={this.handleDateChange}*/}
                {/*//onChange={this.handleChange}*/}
                {/*//onChange={inputChange('plaintiff_date_of_birth')}*/}
                {/*onClickOutside={this.datePickerValue}*/}
                {/*maxDate={new Date()}*/}
                {/*dateFormat="dd-MM-yyyy"*/}
                {/*yearDropdownItemNumber={100}*/}
                {/*scrollableYearDropdown={true}*/}
                {/*showYearDropdown*/}
                {/*showMonthDropdown*/}
                {/*value={values.plaintiff_date_of_birth=this.datePickerValue}*/}

                {/*/>*/}

                <input
                  type="date"
                  className="form-control"
                  id="plaintiff_date_of_birth"
                  name="plaintiff_date_of_birth"
                  onChange={inputChange("plaintiff_date_of_birth")}
                  value={values.plaintiff_date_of_birth}
                  dataformatas="YYYY-MM-DD"
                />

                <span
                  id="required_span_plaintiff_date_of_birth"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Date of Birth
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="plaintiff_state_birth" className="required">
                  State of Birth:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="plaintiff_state_birth"
                  name="plaintiff_state_birth"
                  onChange={inputChange("plaintiff_state_birth")}
                  value={values.plaintiff_state_birth}
                />
                <span
                  id="required_span_plaintiff_state_birth"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill State of Birth
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="plaintiff_county_birth" className="required">
                  County of Birth:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="plaintiff_county_birth"
                  name="plaintiff_county_birth"
                  onChange={inputChange("plaintiff_county_birth")}
                  value={values.plaintiff_county_birth}
                />
                <span
                  id="required_span_plaintiff_county_birth"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill County of Birth
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="plaintiff_country_birth" className="required">
                  Country of Birth:
                </label>
                <Form.Select
                  aria-label="Country of Birth"
                  className="form-control"
                  id="plaintiff_country_birth"
                  name="plaintiff_country_birth"
                  onChange={inputChange("plaintiff_country_birth")}
                  value={values.plaintiff_country_birth}
                >
                  <option value="">Select Country</option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia & Herzegovina">
                    Bosnia & Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">
                    British Indian Ocean Ter
                  </option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Canary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">
                    French Southern Ter
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">
                    Netherland Antilles
                  </option>
                  <option value="Netherlands">
                    Netherlands (Holland, Europe)
                  </option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">
                    Republic of Montenegro
                  </option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre & Miquelon">
                    St Pierre & Miquelon
                  </option>
                  <option value="St Vincent & Grenadines">
                    St Vincent & Grenadines
                  </option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome & Principe">
                    Sao Tome & Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">
                    United Arab Emirates
                  </option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">
                    Virgin Islands (Brit)
                  </option>
                  <option value="Virgin Islands (USA)">
                    Virgin Islands (USA)
                  </option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </Form.Select>
                <span
                  id="required_span_plaintiff_country_birth"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Country of Birth
                </span>
              </div>
              <br />

              <div className="form-group">
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="plaintiff_social_security"
                  name="plaintiff_social_security"
                  value={values.plaintiff_social_security}
                />{" "}
                <br />
                <Form.Label className="">
                  Does Plaintiff Have Social Security Number/Tax ID Number?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Yes"
                  id="plaintiff_social_security_yes"
                  name="plaintiff_social_security"
                  label="Yes"
                  onChange={inputChange("plaintiff_social_security")}
                  onClick={this.showSocialSecurityTaxIDInputBox}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="No"
                  id="plaintiff_social_security_no"
                  name="plaintiff_social_security"
                  label="No"
                  onChange={inputChange("plaintiff_social_security")}
                  onClick={this.showSocialSecurityTaxIDInputBoxHaveNotBecause}
                />
                <br />
                <div
                  id="social_security_tax_id_input_box"
                  className="form-group"
                  style={{ display: "none" }}
                >
                  <label
                    htmlFor="Plaintiff Social Security / Tax ID Input Box"
                    className=""
                  >
                    Social Security / Tax ID Number:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="plaintiff_social_sec_tax_has"
                    name="plaintiff_social_sec_tax_has"
                    onChange={inputChange("plaintiff_social_sec_tax_has")}
                    value={values.plaintiff_social_sec_tax_has}
                  />
                </div>
                <div
                  id="social_security_tax_id_input_box_have_not_because"
                  className="form-group"
                  style={{ display: "none" }}
                >
                  <label
                    htmlFor="Explain Why Have Not Plaintiff Social Security Tax ID Input Box"
                    className=""
                  >
                    Please Explain:
                  </label>
                  <textarea
                    className="form-control"
                    id="plaintiff_social_sec_tax_hasnot"
                    name="plaintiff_social_sec_tax_hasnot"
                    rows="5"
                    onChange={inputChange("plaintiff_social_sec_tax_hasnot")}
                    value={values.plaintiff_social_sec_tax_hasnot}
                  ></textarea>
                </div>
              </div>

              <br />

              <br />
              <div className="form-group">
                <label
                  htmlFor="plaintiff_gross_annual_income"
                  className="required"
                >
                  Plaintiff’s Gross Annual Income:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="plaintiff_gross_annual_income"
                  name="plaintiff_gross_annual_income"
                  onChange={inputChange("plaintiff_gross_annual_income")}
                  value={values.plaintiff_gross_annual_income}
                />
                <span
                  id="required_span_plaintiff_gross_annual_income"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Plaintiff’s Gross Annual Income
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="plaintiff_ny_city_yonkers_tax">
                  Plaintiff’s NY City/Yonkers Tax:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="plaintiff_ny_city_yonkers_tax"
                  name="plaintiff_ny_city_yonkers_tax"
                  onChange={inputChange("plaintiff_ny_city_yonkers_tax")}
                  value={values.plaintiff_ny_city_yonkers_tax}
                />
                <span
                  id="required_span_plaintiff_ny_city_yonkers_tax"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Plaintiff’s NY City/Yonkers Tax
                </span>
              </div>

              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepFour;
