import React, { Component, Fragment } from "react";
import HomePage from "../pages/HomePage";
import IntakePage from "../pages/IntakePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DivorceWithoutChildrenForm from "../components/Forms/DivorceWithoutChildrenForm";
import NoneOfAbovePages from "../pages/NoneOfAbovePages";
import DivorceWithoutChildrenFormConfirm from "../components/Forms/DivorceWithoutChildrenFormConfirm";

class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <Routes>
          <Route ecact path="/" element={<HomePage />} />
          <Route ecact path="/intake" element={<IntakePage />} />
          <Route
            ecact
            path="/IntakeForm"
            element={<DivorceWithoutChildrenForm />}
          />
          <Route ecact path="/none-of-above" element={<NoneOfAbovePages />} />
        </Routes>
      </Fragment>
    );
  }
}

export default AppRoute;
