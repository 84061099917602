import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepFive extends Component {
  continue = (e) => {
    var defendant_is_in_the_military = document.getElementById(
      "defendant_is_in_the_military"
    );
    var required_span_defendant_is_in_the_military = document.getElementById(
      "required_span_defendant_is_in_the_military"
    );
    if (defendant_is_in_the_military.value === "") {
      var att = document.createAttribute("required");
      defendant_is_in_the_military.setAttributeNode(att);
      required_span_defendant_is_in_the_military.style.display = "block";
      cogoToast.error("Please Select Defendant is in the Military?", {
        position: "top-center",
      });
    } else {
      e.preventDefault();
      required_span_defendant_is_in_the_military.style.display = "none";
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const { values, inputChange } = this.props;
    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8} sm={12}>
            <div className="container">
              <h4 className="mb-5">Defendant’s Military Status</h4>
              <div className="form-group">
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "RED",
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "RED",
                    }}
                  >
                    {" "}
                    Required
                  </span>
                </p>
                <label
                  htmlFor="defendant_is_in_the_military"
                  style={{ display: "block" }}
                  className="required"
                >
                  Defendant is in the military?
                </label>
                <Form.Text className="text-muted">
                  IF DEFENDANT IS IN THE MILITARY - DEFENDANT MUST CONSENT AND
                  SIGN OUR PAPERS OR WE CAN’T HELP YOU
                </Form.Text>
                <Form.Select
                  aria-label="Defendant’s Military Status"
                  id="defendant_is_in_the_military"
                  name="defendant_is_in_the_military"
                  onChange={inputChange("defendant_is_in_the_military")}
                  value={values.defendant_is_in_the_military}
                >
                  <option value="">Please Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <span
                  id="required_span_defendant_is_in_the_military"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select Defendant is in the military?
                </span>
              </div>

              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepFive;
