import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

class NoneOfAbove extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <br />
              <br />
              <br />
              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                <img src="./images/none_of_above_emoji_1.png" />
              </div>
              <br />
              <br />
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                We are sorry but based on your and your spouse’s residency, you
                do not qualify to file a divorce in New York.
              </div>
              <br />
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default NoneOfAbove;
