import React, { Component, Fragment } from "react";
import {
  Container,
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

class TopNavigation extends Component {
  render() {
    return (
      <Fragment>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand to="/">
              <img src="./images/GetDivorce101_logo.png" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link>
                  <NavLink to="/" className="navbar_text">
                    Home
                  </NavLink>
                </Nav.Link>
                <Nav.Link>
                  <NavLink to="/intake" className="navbar_text">
                    Intake
                  </NavLink>
                </Nav.Link>
                <Nav.Link></Nav.Link>
                <a
                  href="https://admin.getdivorce101.com/"
                  style={{
                    color: "#FFFFFF",
                    textDecoration: "none",
                    paddingTop: "8px",
                  }}
                >
                  Client Panel
                </a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Fragment>
    );
  }
}

export default TopNavigation;
