import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "reactstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

import swal from "sweetalert";

class DivorceWithoutChildrenFormConfirm extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  submitIntakeForm() {
    // Two
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let mobile = document.getElementById("mobile").value;
    let password = document.getElementById("password").value;

    // One
    let form_prefix = document.getElementById("form_prefix").value;
    let form_name = document.getElementById("form_name").value;
    let group_one = document.getElementById("group_one").value;
    let group_two = document.getElementById("group_two").value;
    let group_three = document.getElementById("group_three").value;
    let group_four = document.getElementById("group_four").value;
    let group_five = document.getElementById("group_five").value;

    // Three
    let plaintiff_first_name = document.getElementById(
      "plaintiff_first_name"
    ).value;
    let plaintiff_last_name = document.getElementById(
      "plaintiff_last_name"
    ).value;
    let plaintiff_maiden_name = document.getElementById(
      "plaintiff_maiden_name"
    ).value;
    let plaintiff_email = document.getElementById("plaintiff_email").value;
    let plaintiff_phone = document.getElementById("plaintiff_phone").value;
    let plaintiff_street_address = document.getElementById(
      "plaintiff_street_address"
    ).value;
    let plaintiff_city = document.getElementById("plaintiff_city").value;
    let plaintiff_state = document.getElementById("plaintiff_state").value;
    let plaintiff_zip = document.getElementById("plaintiff_zip").value;
    let plaintiff_county = document.getElementById("plaintiff_county").value;
    let plaintiff_country = document.getElementById("plaintiff_country").value;

    // Four
    let plaintiff_date_of_birth = document.getElementById(
      "plaintiff_date_of_birth"
    ).value;
    let plaintiff_state_birth = document.getElementById(
      "plaintiff_state_birth"
    ).value;
    let plaintiff_county_birth = document.getElementById(
      "plaintiff_county_birth"
    ).value;
    let plaintiff_country_birth = document.getElementById(
      "plaintiff_country_birth"
    ).value;
    let plaintiff_social_security = document.getElementById(
      "plaintiff_social_security"
    ).value;
    let plaintiff_social_sec_tax_has = document.getElementById(
      "plaintiff_social_sec_tax_has"
    ).value;
    let plaintiff_social_sec_tax_hasnot = document.getElementById(
      "plaintiff_social_sec_tax_hasnot"
    ).value;
    let plaintiff_gross_annual_income = document.getElementById(
      "plaintiff_gross_annual_income"
    ).value;
    let plaintiff_ny_city_yonkers_tax = document.getElementById(
      "plaintiff_ny_city_yonkers_tax"
    ).value;

    // Five
    let defendant_is_in_the_military = document.getElementById(
      "defendant_is_in_the_military"
    ).value;

    // Six
    let defendent_sign_divorce_paper_yn = document.getElementById(
      "defendent_sign_divorce_paper_yn"
    ).value;

    // Seven
    let defendent_male_female = document.getElementById(
      "defendent_male_female"
    ).value;
    let defendant_first_name = document.getElementById(
      "defendant_first_name"
    ).value;
    let defendant_last_name = document.getElementById(
      "defendant_last_name"
    ).value;
    let defendant_maiden_name = document.getElementById(
      "defendant_maiden_name"
    ).value;
    let defendant_email = document.getElementById("defendant_email").value;
    let defendant_phone = document.getElementById("defendant_phone").value;
    let defendant_street_address = document.getElementById(
      "defendant_street_address"
    ).value;
    let defendant_city = document.getElementById("defendant_city").value;
    let defendant_state = document.getElementById("defendant_state").value;
    let defendant_zip = document.getElementById("defendant_zip").value;
    let defendant_county_residence = document.getElementById(
      "defendant_county_residence"
    ).value;
    let defendant_country_residence = document.getElementById(
      "defendant_country_residence"
    ).value;

    // Eight
    let defendant_date_of_birth = document.getElementById(
      "defendant_date_of_birth"
    ).value;
    let defendant_state_of_birth = document.getElementById(
      "defendant_state_of_birth"
    ).value;
    let defendant_county_of_birth = document.getElementById(
      "defendant_county_of_birth"
    ).value;
    let defendant_country_of_birth = document.getElementById(
      "defendant_country_of_birth"
    ).value;
    let defendant_social_security = document.getElementById(
      "defendant_social_security"
    ).value;
    let defendant_social_sec_tax_has = document.getElementById(
      "defendant_social_sec_tax_has"
    ).value;
    let defendant_social_sec_tax_hasnot = document.getElementById(
      "defendant_social_sec_tax_hasnot"
    ).value;
    let defendant_gross_annual_income = document.getElementById(
      "defendant_gross_annual_income"
    ).value;
    let defendant_ny_city_yonkers_tax = document.getElementById(
      "defendant_ny_city_yonkers_tax"
    ).value;

    // Nine
    let file_submit_county =
      document.getElementById("file_submit_county").value;

    // Ten
    let type_wedding_ceremony = document.getElementById(
      "type_wedding_ceremony"
    ).value;
    let city_married_party =
      document.getElementById("city_married_party").value;
    let state_married_party = document.getElementById(
      "state_married_party"
    ).value;
    let country_married_party = document.getElementById(
      "country_married_party"
    ).value;
    let date_of_marriage = document.getElementById("date_of_marriage").value;

    // Eleven
    let complete_assets_debts_workbook_yes_no = document.getElementById(
      "complete_assets_debts_workbook_yes_no"
    ).value;
    let want_settlement_yes_no = document.getElementById(
      "want_settlement_yes_no"
    ).value;
    let already_separation_yes_no = document.getElementById(
      "already_separation_yes_no"
    ).value;

    // Twelve
    let pay_receive_spousal = document.getElementById(
      "pay_receive_spousal"
    ).value;
    let pay_recv_spous_maint_opt = document.getElementById(
      "pay_recv_spous_maint_opt"
    ).value;
    let pay_recv_spous_maint_opt_one = document.getElementById(
      "pay_recv_spous_maint_opt_one"
    ).value;
    let maint_pd_dt_pd_sup_colct_opt_one = document.getElementById(
      "maint_pd_dt_pd_sup_colct_opt_one"
    ).value;
    let pay_recv_spous_maint_opt_two = document.getElementById(
      "pay_recv_spous_maint_opt_two"
    ).value;
    let how_much_maint_per_month = document.getElementById(
      "how_much_maint_per_month"
    ).value;
    let maint_start_date = document.getElementById("maint_start_date").value;
    let maint_end_date = document.getElementById("maint_end_date").value;
    let maint_pd_dt_pd_sup_colct_opt_two = document.getElementById(
      "maint_pd_dt_pd_sup_colct_opt_two"
    ).value;

    //alert(plaintiff_first_name+" --- "+plaintiff_last_name+" --- "+plaintiff_maiden_name);

    let jsonObject = {
      name: name,
      email: email,
      mobile: mobile,
      password: password,
      form_prefix: form_prefix,
      form_name: form_name,
      group_one: group_one,
      group_two: group_two,
      group_three: group_three,
      group_four: group_four,
      group_five: group_five,
      plaintiff_first_name: plaintiff_first_name,
      plaintiff_last_name: plaintiff_last_name,
      plaintiff_maiden_name: plaintiff_maiden_name,
      plaintiff_email: plaintiff_email,
      plaintiff_phone: plaintiff_phone,
      plaintiff_street_address: plaintiff_street_address,
      plaintiff_city: plaintiff_city,
      plaintiff_state: plaintiff_state,
      plaintiff_zip: plaintiff_zip,
      plaintiff_county: plaintiff_county,
      plaintiff_country: plaintiff_country,
      plaintiff_date_of_birth: plaintiff_date_of_birth,
      plaintiff_state_birth: plaintiff_state_birth,
      plaintiff_county_birth: plaintiff_county_birth,
      plaintiff_country_birth: plaintiff_country_birth,
      plaintiff_social_security: plaintiff_social_security,
      plaintiff_social_sec_tax_has: plaintiff_social_sec_tax_has,
      plaintiff_social_sec_tax_hasnot: plaintiff_social_sec_tax_hasnot,
      plaintiff_gross_annual_income: plaintiff_gross_annual_income,
      plaintiff_ny_city_yonkers_tax: plaintiff_ny_city_yonkers_tax,
      defendant_is_in_the_military: defendant_is_in_the_military,
      defendent_sign_divorce_paper_yn: defendent_sign_divorce_paper_yn,
      defendent_male_female: defendent_male_female,
      defendant_first_name: defendant_first_name,
      defendant_last_name: defendant_last_name,
      defendant_maiden_name: defendant_maiden_name,
      defendant_email: defendant_email,
      defendant_phone: defendant_phone,
      defendant_street_address: defendant_street_address,
      defendant_city: defendant_city,
      defendant_state: defendant_state,
      defendant_zip: defendant_zip,
      defendant_county_residence: defendant_county_residence,
      defendant_country_residence: defendant_country_residence,
      defendant_date_of_birth: defendant_date_of_birth,
      defendant_state_of_birth: defendant_state_of_birth,
      defendant_county_of_birth: defendant_county_of_birth,
      defendant_country_of_birth: defendant_country_of_birth,
      defendant_social_security: defendant_social_security,
      defendant_social_sec_tax_has: defendant_social_sec_tax_has,
      defendant_social_sec_tax_hasnot: defendant_social_sec_tax_hasnot,
      defendant_gross_annual_income: defendant_gross_annual_income,
      defendant_ny_city_yonkers_tax: defendant_ny_city_yonkers_tax,
      file_submit_county: file_submit_county,
      type_wedding_ceremony: type_wedding_ceremony,
      city_married_party: city_married_party,
      state_married_party: state_married_party,
      country_married_party: country_married_party,
      date_of_marriage: date_of_marriage,
      complete_assets_debts_workbook_yes_no:
        complete_assets_debts_workbook_yes_no,
      want_settlement_yes_no: want_settlement_yes_no,
      already_separation_yes_no: already_separation_yes_no,
      pay_receive_spousal: pay_receive_spousal,
      pay_recv_spous_maint_opt: pay_recv_spous_maint_opt,
      pay_recv_spous_maint_opt_one: pay_recv_spous_maint_opt_one,
      maint_pd_dt_pd_sup_colct_opt_one: maint_pd_dt_pd_sup_colct_opt_one,
      pay_recv_spous_maint_opt_two: pay_recv_spous_maint_opt_two,
      how_much_maint_per_month: how_much_maint_per_month,
      maint_start_date: maint_start_date,
      maint_end_date: maint_end_date,
      maint_pd_dt_pd_sup_colct_opt_two: maint_pd_dt_pd_sup_colct_opt_two,
    };

    RestClient.PostRequest(AppUrl.IntakeFormSave, jsonObject)
      .then((result) => {
        //alert(result);
        swal({
          title: "Intake Successfully Submitted",
          //text: "Message!",
          type: "success",
        }).then(function () {
          window.location = "https://getdivorce101.com/#/IntakeForm";
        });
      })
      .catch((error) => {
        alert("Error");
      });
  }

  render() {
    const {
      values: {
        // One
        form_prefix,
        form_name,
        group_one,
        group_two,
        group_three,
        group_four,
        group_five,

        // Two
        name,
        email,
        mobile,
        password,

        // Three
        plaintiff_first_name,
        plaintiff_last_name,
        plaintiff_maiden_name,
        plaintiff_email,
        plaintiff_phone,
        plaintiff_street_address,
        plaintiff_city,
        plaintiff_state,
        plaintiff_zip,
        plaintiff_county,
        plaintiff_country,

        // Four
        plaintiff_date_of_birth,
        plaintiff_state_birth,
        plaintiff_county_birth,
        plaintiff_country_birth,
        plaintiff_social_security,
        plaintiff_social_sec_tax_has,
        plaintiff_social_sec_tax_hasnot,
        plaintiff_gross_annual_income,
        plaintiff_ny_city_yonkers_tax,

        // Five
        defendant_is_in_the_military,

        // Six
        defendent_sign_divorce_paper_yn,

        // Seven
        defendent_male_female,
        defendant_first_name,
        defendant_last_name,
        defendant_maiden_name,
        defendant_email,
        defendant_phone,
        defendant_street_address,
        defendant_city,
        defendant_state,
        defendant_zip,
        defendant_county_residence,
        defendant_country_residence,

        // Eight
        defendant_date_of_birth,
        defendant_state_of_birth,
        defendant_county_of_birth,
        defendant_country_of_birth,
        defendant_social_security,
        defendant_social_sec_tax_has,
        defendant_social_sec_tax_hasnot,
        defendant_gross_annual_income,
        defendant_ny_city_yonkers_tax,

        // Nine
        file_submit_county,

        // Ten
        type_wedding_ceremony,
        city_married_party,
        state_married_party,
        country_married_party,
        date_of_marriage,

        // Eleven
        complete_assets_debts_workbook_yes_no,
        want_settlement_yes_no,
        already_separation_yes_no,

        // Twelve
        pay_receive_spousal,
        pay_recv_spous_maint_opt,
        pay_recv_spous_maint_opt_one,
        maint_pd_dt_pd_sup_colct_opt_one,
        pay_recv_spous_maint_opt_two,
        how_much_maint_per_month,
        maint_start_date,
        maint_end_date,
        maint_pd_dt_pd_sup_colct_opt_two,
      },
    } = this.props;

    return (
      <div className="form-container">
        <Container className="mt-lg-5 mb-5">
          <Form>
            <input
              type="hidden"
              id="form_prefix"
              name="form_prefix"
              value={form_prefix}
            />
            <input
              type="hidden"
              id="form_name"
              name="form_name"
              value={form_name}
            />
            <input
              type="hidden"
              id="group_one"
              name="group_one"
              value={group_one}
            />
            <input
              type="hidden"
              id="group_two"
              name="group_two"
              value={group_two}
            />
            <input
              type="hidden"
              id="group_three"
              name="group_three"
              value={group_three}
            />
            <input
              type="hidden"
              id="group_four"
              name="group_four"
              value={group_four}
            />
            <input
              type="hidden"
              id="group_five"
              name="group_five"
              value={group_five}
            />

            <input type="hidden" id="name" name="name" value={name} />
            <input type="hidden" id="email" name="email" value={email} />
            <input type="hidden" id="mobile" name="mobile" value={mobile} />
            <input
              type="hidden"
              id="password"
              name="password"
              value={password}
            />

            <input
              type="hidden"
              id="plaintiff_first_name"
              name="plaintiff_first_name"
              value={plaintiff_first_name}
            />
            <input
              type="hidden"
              id="plaintiff_last_name"
              name="plaintiff_last_name"
              value={plaintiff_last_name}
            />
            <input
              type="hidden"
              id="plaintiff_maiden_name"
              name="plaintiff_maiden_name"
              value={plaintiff_maiden_name}
            />
            <input
              type="hidden"
              id="plaintiff_email"
              name="plaintiff_email"
              value={plaintiff_email}
            />
            <input
              type="hidden"
              id="plaintiff_phone"
              name="plaintiff_phone"
              value={plaintiff_phone}
            />
            <input
              type="hidden"
              id="plaintiff_street_address"
              name="plaintiff_street_address"
              value={plaintiff_street_address}
            />
            <input
              type="hidden"
              id="plaintiff_city"
              name="plaintiff_city"
              value={plaintiff_city}
            />
            <input
              type="hidden"
              id="plaintiff_state"
              name="plaintiff_state"
              value={plaintiff_state}
            />
            <input
              type="hidden"
              id="plaintiff_zip"
              name="plaintiff_zip"
              value={plaintiff_zip}
            />
            <input
              type="hidden"
              id="plaintiff_county"
              name="plaintiff_county"
              value={plaintiff_county}
            />
            <input
              type="hidden"
              id="plaintiff_country"
              name="plaintiff_country"
              value={plaintiff_country}
            />

            <input
              type="hidden"
              id="plaintiff_date_of_birth"
              name="plaintiff_date_of_birth"
              value={plaintiff_date_of_birth}
            />
            <input
              type="hidden"
              id="plaintiff_state_birth"
              name="plaintiff_state_birth"
              value={plaintiff_state_birth}
            />
            <input
              type="hidden"
              id="plaintiff_county_birth"
              name="plaintiff_county_birth"
              value={plaintiff_county_birth}
            />
            <input
              type="hidden"
              id="plaintiff_country_birth"
              name="plaintiff_country_birth"
              value={plaintiff_country_birth}
            />
            <input
              type="hidden"
              id="plaintiff_social_security"
              name="plaintiff_social_security"
              value={plaintiff_social_security}
            />
            <input
              type="hidden"
              id="plaintiff_social_sec_tax_has"
              name="plaintiff_social_sec_tax_has"
              value={plaintiff_social_sec_tax_has}
            />
            <input
              type="hidden"
              id="plaintiff_social_sec_tax_hasnot"
              name="plaintiff_social_sec_tax_hasnot"
              value={plaintiff_social_sec_tax_hasnot}
            />
            <input
              type="hidden"
              id="plaintiff_gross_annual_income"
              name="plaintiff_gross_annual_income"
              value={plaintiff_gross_annual_income}
            />
            <input
              type="hidden"
              id="plaintiff_ny_city_yonkers_tax"
              name="plaintiff_ny_city_yonkers_tax"
              value={plaintiff_ny_city_yonkers_tax}
            />

            <input
              type="hidden"
              id="defendant_is_in_the_military"
              name="defendant_is_in_the_military"
              value={defendant_is_in_the_military}
            />

            <input
              type="hidden"
              id="defendent_sign_divorce_paper_yn"
              name="defendent_sign_divorce_paper_yn"
              value={defendent_sign_divorce_paper_yn}
            />

            <input
              type="hidden"
              id="defendent_male_female"
              name="defendent_male_female"
              value={defendent_male_female}
            />
            <input
              type="hidden"
              id="defendant_first_name"
              name="defendant_first_name"
              value={defendant_first_name}
            />
            <input
              type="hidden"
              id="defendant_last_name"
              name="defendant_last_name"
              value={defendant_last_name}
            />
            <input
              type="hidden"
              id="defendant_maiden_name"
              name="defendant_maiden_name"
              value={defendant_maiden_name}
            />
            <input
              type="hidden"
              id="defendant_email"
              name="defendant_email"
              value={defendant_email}
            />
            <input
              type="hidden"
              id="defendant_phone"
              name="defendant_phone"
              value={defendant_phone}
            />
            <input
              type="hidden"
              id="defendant_street_address"
              name="defendant_street_address"
              value={defendant_street_address}
            />
            <input
              type="hidden"
              id="defendant_city"
              name="defendant_city"
              value={defendant_city}
            />
            <input
              type="hidden"
              id="defendant_state"
              name="defendant_state"
              value={defendant_state}
            />
            <input
              type="hidden"
              id="defendant_zip"
              name="defendant_zip"
              value={defendant_zip}
            />
            <input
              type="hidden"
              id="defendant_county_residence"
              name="defendant_county_residence"
              value={defendant_county_residence}
            />
            <input
              type="hidden"
              id="defendant_country_residence"
              name="defendant_country_residence"
              value={defendant_country_residence}
            />

            <input
              type="hidden"
              id="defendant_date_of_birth"
              name="defendant_date_of_birth"
              value={defendant_date_of_birth}
            />
            <input
              type="hidden"
              id="defendant_date_of_birth"
              name="defendant_date_of_birth"
              value={defendant_date_of_birth}
            />
            <input
              type="hidden"
              id="defendant_state_of_birth"
              name="defendant_state_of_birth"
              value={defendant_state_of_birth}
            />
            <input
              type="hidden"
              id="defendant_county_of_birth"
              name="defendant_county_of_birth"
              value={defendant_county_of_birth}
            />
            <input
              type="hidden"
              id="defendant_country_of_birth"
              name="defendant_country_of_birth"
              value={defendant_country_of_birth}
            />
            <input
              type="hidden"
              id="defendant_social_security"
              name="defendant_social_security"
              value={defendant_social_security}
            />
            <input
              type="hidden"
              id="defendant_social_sec_tax_has"
              name="defendant_social_sec_tax_has"
              value={defendant_social_sec_tax_has}
            />
            <input
              type="hidden"
              id="defendant_social_sec_tax_hasnot"
              name="defendant_social_sec_tax_hasnot"
              value={defendant_social_sec_tax_hasnot}
            />
            <input
              type="hidden"
              id="defendant_gross_annual_income"
              name="defendant_gross_annual_income"
              value={defendant_gross_annual_income}
            />
            <input
              type="hidden"
              id="defendant_ny_city_yonkers_tax"
              name="defendant_ny_city_yonkers_tax"
              value={defendant_ny_city_yonkers_tax}
            />

            <input
              type="hidden"
              id="file_submit_county"
              name="file_submit_county"
              value={file_submit_county}
            />

            <input
              type="hidden"
              id="type_wedding_ceremony"
              name="type_wedding_ceremony"
              value={type_wedding_ceremony}
            />
            <input
              type="hidden"
              id="city_married_party"
              name="city_married_party"
              value={city_married_party}
            />
            <input
              type="hidden"
              id="state_married_party"
              name="state_married_party"
              value={state_married_party}
            />
            <input
              type="hidden"
              id="country_married_party"
              name="country_married_party"
              value={country_married_party}
            />
            <input
              type="hidden"
              id="date_of_marriage"
              name="date_of_marriage"
              value={date_of_marriage}
            />

            <input
              type="hidden"
              id="complete_assets_debts_workbook_yes_no"
              name="complete_assets_debts_workbook_yes_no"
              value={complete_assets_debts_workbook_yes_no}
            />
            <input
              type="hidden"
              id="want_settlement_yes_no"
              name="want_settlement_yes_no"
              value={want_settlement_yes_no}
            />
            <input
              type="hidden"
              id="already_separation_yes_no"
              name="already_separation_yes_no"
              value={already_separation_yes_no}
            />

            <input
              type="hidden"
              id="pay_receive_spousal"
              name="pay_receive_spousal"
              value={pay_receive_spousal}
            />
            <input
              type="hidden"
              id="pay_recv_spous_maint_opt"
              name="pay_recv_spous_maint_opt"
              value={pay_recv_spous_maint_opt}
            />
            <input
              type="hidden"
              id="pay_recv_spous_maint_opt_one"
              name="pay_recv_spous_maint_opt_one"
              value={pay_recv_spous_maint_opt_one}
            />
            <input
              type="hidden"
              id="maint_pd_dt_pd_sup_colct_opt_one"
              name="maint_pd_dt_pd_sup_colct_opt_one"
              value={maint_pd_dt_pd_sup_colct_opt_one}
            />
            <input
              type="hidden"
              id="pay_recv_spous_maint_opt_two"
              name="pay_recv_spous_maint_opt_two"
              value={pay_recv_spous_maint_opt_two}
            />
            <input
              type="hidden"
              id="how_much_maint_per_month"
              name="how_much_maint_per_month"
              value={how_much_maint_per_month}
            />
            <input
              type="hidden"
              id="maint_start_date"
              name="maint_start_date"
              value={maint_start_date}
            />
            <input
              type="hidden"
              id="maint_end_date"
              name="maint_end_date"
              value={maint_end_date}
            />
            <input
              type="hidden"
              id="maint_pd_dt_pd_sup_colct_opt_two"
              name="maint_pd_dt_pd_sup_colct_opt_two"
              value={maint_pd_dt_pd_sup_colct_opt_two}
            />
          </Form>

          <Row>
            <Col lg={3} md={2} sm={1}>
              &nbsp;
            </Col>
            <Col lg={6} md={8} sm={10}>
              <h1 className="mb-5">Confirm</h1>

              <br />

              <h4>FORM NAME</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Form Prefix:</span>{" "}
                  {form_prefix}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Form Name:</span>{" "}
                  {form_name}
                </li>
              </ul>

              <br />

              <h4>RESIDENCY OPTION</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Residency Option 1:</span>{" "}
                  {group_one}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Residency Option 2:</span>{" "}
                  {group_two}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Residency Option 3:</span>{" "}
                  {group_three}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Residency Option 4:</span>{" "}
                  {group_four}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Residency Option 5:</span>{" "}
                  {group_five}
                </li>
              </ul>
              <br />

              <h4>ACCOUNT INFORMATION</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Name:</span> {name}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Email:</span> {email}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Mobile:</span> {mobile}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Password:</span>{" "}
                  {password}
                </li>
              </ul>
              <br />

              <h4>INFORMATION ABOUT THE PLAINTIFF</h4>
              <ul className="list-group form-group">
                <li
                  className="list-group-item"
                  id="plaintiff_first_name"
                  name="plaintiff_first_name"
                >
                  <span style={{ fontWeight: "500" }}>
                    Plaintiff’s First Name:
                  </span>{" "}
                  {plaintiff_first_name}
                </li>
                <li
                  className="list-group-item"
                  id="plaintiff_last_name"
                  name="plaintiff_last_name"
                >
                  <span style={{ fontWeight: "500" }}>
                    Plaintiff’s Last Name:
                  </span>{" "}
                  {plaintiff_last_name}
                </li>
                <li
                  className="list-group-item"
                  id="plaintiff_maiden_name"
                  name="plaintiff_maiden_name"
                >
                  <span style={{ fontWeight: "500" }}>
                    Plaintiff’s Prior Surname (maiden name):
                  </span>{" "}
                  {plaintiff_maiden_name}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Email:</span>{" "}
                  {plaintiff_email}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Phone Number:</span>{" "}
                  {plaintiff_phone}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Street Address:</span>{" "}
                  {plaintiff_street_address}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>City:</span>{" "}
                  {plaintiff_city}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>State:</span>{" "}
                  {plaintiff_state}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>ZIP:</span>{" "}
                  {plaintiff_zip}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    County of Residence:
                  </span>{" "}
                  {plaintiff_county}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Country of Residence:
                  </span>{" "}
                  {plaintiff_country}
                </li>
              </ul>
              <br />

              <h4>ADDITIONAL INFORMATION ABOUT THE PLAINTIFF:</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Date of Birth:</span>{" "}
                  {plaintiff_date_of_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>State of Birth:</span>{" "}
                  {plaintiff_state_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>County of Birth:</span>{" "}
                  {plaintiff_county_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Country of Birth:</span>{" "}
                  {plaintiff_country_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Social Security / Tax ID Number:
                  </span>{" "}
                  {plaintiff_social_sec_tax_has}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Explanation:</span>{" "}
                  {plaintiff_social_sec_tax_hasnot}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Plaintiff’s Gross Annual Income:
                  </span>{" "}
                  {plaintiff_gross_annual_income}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Plaintiff’s NY City/Yonkers Tax:
                  </span>{" "}
                  {plaintiff_ny_city_yonkers_tax}
                </li>
              </ul>
              <br />

              <h4>Defendant’s Military Status</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant’s Military Status:
                  </span>{" "}
                  {defendant_is_in_the_military}
                </li>
              </ul>
              <br />

              <h4>Will your spouse sign the divorce papers?</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant will sign the divorce papers?
                  </span>{" "}
                  {defendent_sign_divorce_paper_yn}
                </li>
              </ul>
              <br />

              <h4>INFORMATION ABOUT THE DEFENDANT</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Defendant is:</span>{" "}
                  {defendent_male_female}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant’s First Name:
                  </span>{" "}
                  {defendant_first_name}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant’s Last Name:
                  </span>{" "}
                  {defendant_last_name}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant’s Prior Surname (maiden name):
                  </span>{" "}
                  {defendant_maiden_name}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Defendant’s Email:</span>{" "}
                  {defendant_email}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Defendant’s Phone:</span>{" "}
                  {defendant_phone}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Street Address:</span>{" "}
                  {defendant_street_address}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>City:</span>{" "}
                  {defendant_city}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>State:</span>{" "}
                  {defendant_state}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>ZIP:</span>{" "}
                  {defendant_zip}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    County of Residence:
                  </span>{" "}
                  {defendant_county_residence}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Country of Residence:
                  </span>{" "}
                  {defendant_country_residence}
                </li>
              </ul>
              <br />

              <h4>ADDITIONAL INFORMATION ABOUT THE DEFENDANT:</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Date of Birth:</span>{" "}
                  {defendant_date_of_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>State of Birth:</span>{" "}
                  {defendant_state_of_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>County of Birth:</span>{" "}
                  {defendant_county_of_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Country of Birth:</span>{" "}
                  {defendant_country_of_birth}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Social Security / Tax ID Number:
                  </span>{" "}
                  {defendant_social_sec_tax_has}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Explanation:</span>{" "}
                  {defendant_social_sec_tax_hasnot}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant’s Gross Annual Income:
                  </span>{" "}
                  {defendant_gross_annual_income}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Defendant’s NY City/Yonkers Tax:
                  </span>{" "}
                  {defendant_ny_city_yonkers_tax}
                </li>
              </ul>
              <br />

              <h4>What COUNTY do you want to file your divorce in?</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>County:</span>{" "}
                  {file_submit_county}
                </li>
              </ul>
              <br />

              <h4>Wedding Ceremony</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    What kind of wedding Ceremony?
                  </span>{" "}
                  {type_wedding_ceremony}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    CITY where the parties were married:
                  </span>{" "}
                  {city_married_party}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    STATE where the parties were married:
                  </span>{" "}
                  {state_married_party}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    COUNTRY where the parties were married:
                  </span>{" "}
                  {country_married_party}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Date of Marriage:</span>{" "}
                  {date_of_marriage}
                </li>
              </ul>
              <br />

              <h4>Assets or Debts</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Are there any ASSETS OR DEBTS to be divided?
                  </span>{" "}
                  {complete_assets_debts_workbook_yes_no}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Do you want a Stipulation of Settlement (Divorce Agreement)
                    to be prepared?
                  </span>{" "}
                  {want_settlement_yes_no}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Do you already have a separation agreement / stipulation?
                  </span>{" "}
                  {already_separation_yes_no}
                </li>
              </ul>
              <br />

              <h4>Spousal Maintenance (Alimony)</h4>
              <ul className="list-group">
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Pay or Receive Spousal Maintenance (Alimony)?
                  </span>{" "}
                  {pay_receive_spousal}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>Option No:</span>{" "}
                  {pay_recv_spous_maint_opt}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Do you want to PAY or RECEIVE Spousal Maintenance?
                  </span>{" "}
                  {pay_recv_spous_maint_opt_one}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Maintenance paid directly OR paid through the Support
                    Collection Unit?
                  </span>{" "}
                  {maint_pd_dt_pd_sup_colct_opt_one}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Do you want to PAY or RECEIVE Spousal Maintenance?
                  </span>{" "}
                  {pay_recv_spous_maint_opt_two}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    How much Maintenance per month?
                  </span>{" "}
                  {how_much_maint_per_month}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Maintenance Start Date?
                  </span>{" "}
                  {maint_start_date}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Maintenance End Date?
                  </span>{" "}
                  {maint_end_date}
                </li>
                <li className="list-group-item">
                  <span style={{ fontWeight: "500" }}>
                    Maintenance paid directly OR paid through the Support
                    Collection Unit?
                  </span>{" "}
                  {maint_pd_dt_pd_sup_colct_opt_two}
                </li>
              </ul>
              <br />

              <br />

              <br />
              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.submitIntakeForm}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={3} md={2} sm={1}>
              &nbsp;
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormConfirm;
