import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import checked from "react-bootstrap/Form";
import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepSeven extends Component {
  state = {
    checked: "Male",
    setChecked: "Male",
    isChecked: "Male",
  };

  continue = (e) => {
    var defendent_male_female = document.getElementById(
      "defendent_male_female"
    );
    var defendant_first_name = document.getElementById("defendant_first_name");
    var defendant_last_name = document.getElementById("defendant_last_name");
    var defendant_email = document.getElementById("defendant_email");
    var defendant_phone = document.getElementById("defendant_phone");
    var defendant_street_address = document.getElementById(
      "defendant_street_address"
    );
    var defendant_city = document.getElementById("defendant_city");
    var defendant_state = document.getElementById("defendant_state");
    var defendant_zip = document.getElementById("defendant_zip");
    var defendant_county_residence = document.getElementById(
      "defendant_county_residence"
    );
    var defendant_country_residence = document.getElementById(
      "defendant_country_residence"
    );

    var required_span_defendent_male_female = document.getElementById(
      "required_span_defendent_male_female"
    );
    var required_span_defendant_first_name = document.getElementById(
      "required_span_defendant_first_name"
    );
    var required_span_defendant_last_name = document.getElementById(
      "required_span_defendant_last_name"
    );
    var required_span_defendant_email = document.getElementById(
      "required_span_defendant_email"
    );
    var required_span_defendant_phone = document.getElementById(
      "required_span_defendant_phone"
    );
    var required_span_defendant_street_address = document.getElementById(
      "required_span_defendant_street_address"
    );
    var required_span_defendant_city = document.getElementById(
      "required_span_defendant_city"
    );
    var required_span_defendant_state = document.getElementById(
      "required_span_defendant_state"
    );
    var required_span_defendant_zip = document.getElementById(
      "required_span_defendant_zip"
    );
    var required_span_defendant_county_residence = document.getElementById(
      "required_span_defendant_county_residence"
    );
    var required_span_defendant_country_residence = document.getElementById(
      "required_span_defendant_country_residence"
    );

    if (defendent_male_female.value === "") {
      var att = document.createAttribute("required");
      defendent_male_female.setAttributeNode(att);
      required_span_defendent_male_female.style.display = "block";
      cogoToast.error("Please Select Defendant Male OR Female", {
        position: "top-center",
      });
    } else if (defendant_first_name.value === "") {
      var att = document.createAttribute("required");
      defendant_first_name.setAttributeNode(att);
      required_span_defendent_male_female.style.display = "none";
      required_span_defendant_first_name.style.display = "block";
      cogoToast.error("Please Put Defendant First Name", {
        position: "top-center",
      });
    } else if (defendant_last_name.value === "") {
      var att = document.createAttribute("required");
      defendant_last_name.setAttributeNode(att);
      required_span_defendant_first_name.style.display = "none";
      required_span_defendant_last_name.style.display = "block";
      cogoToast.error("Please Put Defendant Last Name", {
        position: "top-center",
      });
    } else if (defendant_email.value === "") {
      var att = document.createAttribute("required");
      defendant_email.setAttributeNode(att);
      required_span_defendant_last_name.style.display = "none";
      required_span_defendant_email.style.display = "block";
      cogoToast.error("Please Put Valid Email ", { position: "top-center" });
    } else if (defendant_phone.value === "") {
      var att = document.createAttribute("required");
      defendant_phone.setAttributeNode(att);
      required_span_defendant_email.style.display = "none";
      required_span_defendant_phone.style.display = "block";
      cogoToast.error("Please Put Defendant Phone Number", {
        position: "top-center",
      });
    } else if (defendant_street_address.value === "") {
      var att = document.createAttribute("required");
      defendant_street_address.setAttributeNode(att);
      required_span_defendant_phone.style.display = "none";
      required_span_defendant_street_address.style.display = "block";
      cogoToast.error("Please Put Street Address", { position: "top-center" });
    } else if (defendant_city.value === "") {
      var att = document.createAttribute("required");
      defendant_city.setAttributeNode(att);
      required_span_defendant_street_address.style.display = "none";
      required_span_defendant_city.style.display = "block";
      cogoToast.error("Please Put Defendant City", { position: "top-center" });
    } else if (defendant_state.value === "") {
      var att = document.createAttribute("required");
      defendant_state.setAttributeNode(att);
      required_span_defendant_city.style.display = "none";
      required_span_defendant_state.style.display = "block";
      cogoToast.error("Please Put Defendant State", { position: "top-center" });
    } else if (defendant_zip.value === "") {
      var att = document.createAttribute("required");
      defendant_zip.setAttributeNode(att);
      required_span_defendant_state.style.display = "none";
      required_span_defendant_zip.style.display = "block";
      cogoToast.error("Please Put Defendant Zip", { position: "top-center" });
    } else if (defendant_county_residence.value === "") {
      var att = document.createAttribute("required");
      defendant_county_residence.setAttributeNode(att);
      required_span_defendant_zip.style.display = "none";
      required_span_defendant_county_residence.style.display = "block";
      cogoToast.error("Please Put Defendant County", {
        position: "top-center",
      });
    } else if (defendant_country_residence.value === "") {
      var att = document.createAttribute("required");
      defendant_country_residence.setAttributeNode(att);
      required_span_defendant_county_residence.style.display = "none";
      required_span_defendant_country_residence.style.display = "block";
      cogoToast.error("Please Put Defendant Country", {
        position: "top-center",
      });
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const { values, inputChange, inputChangeChecked } = this.props;

    // if(document.getElementById("defendent_male_female").value==='Male'){
    //     document.getElementById("defendent_male_male").checked = true;
    //     document.getElementById("defendent_female_female").checked = false;
    // }
    // else if(document.getElementById("defendent_male_female").value==='Female'){
    //     document.getElementById("defendent_female_female").checked = true;
    //     document.getElementById("defendent_male_male").checked = false;
    // }

    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8} sm={12}>
            <div className="container">
              <h4 className="mb-5">INFORMATION ABOUT THE DEFENDANT:</h4>
              <p>
                <b>Security & Privacy:</b> This is a secured website. We keep
                your personal information confidential. We do not share your
                information with third parties.
              </p>
              <p>
                <b>Explanation:</b> You cannot use a PO BOX address.
              </p>
              <p>
                <span
                  style={{ fontWeight: "bold", fontSize: "20px", color: "RED" }}
                >
                  *
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px", color: "RED" }}
                >
                  {" "}
                  Required
                </span>
              </p>

              <div className="form-group">
                <Form.Label className="required">Defendant is:</Form.Label>
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="defendent_male_female"
                  name="defendent_male_female"
                  value={values.defendent_male_female}
                />{" "}
                <br />
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Male"
                  id="defendent_male_male"
                  name="defendent_male_female"
                  label="Male"
                  onChange={inputChangeChecked("defendent_male_female")}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Female"
                  id="defendent_female_female"
                  name="defendent_male_female"
                  label="Female"
                  onChange={inputChangeChecked("defendent_male_female")}
                />
                <span
                  id="required_span_defendent_male_female"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select Defendant Male OR Female
                </span>
              </div>
              <br />

              <div className="form-group">
                <label htmlFor="defendant_first_name" className="required">
                  Defendant’s First Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_first_name"
                  name="defendant_first_name"
                  onChange={inputChange("defendant_first_name")}
                  value={values.defendant_first_name}
                />
                <span
                  id="required_span_defendant_first_name"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill First Name
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_last_name" className="required">
                  Defendant’s Last Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_last_name"
                  name="defendant_last_name"
                  onChange={inputChange("defendant_last_name")}
                  value={values.defendant_last_name}
                />
                <span
                  id="required_span_defendant_last_name"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Last Name
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_maiden_name">
                  Defendant’s Prior Surname (maiden name):
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_maiden_name"
                  name="defendant_maiden_name"
                  onChange={inputChange("defendant_maiden_name")}
                  value={values.defendant_maiden_name}
                />
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_email" className="required">
                  Defendant’s Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="defendant_email"
                  name="defendant_email"
                  onChange={inputChange("defendant_email")}
                  value={values.defendant_email}
                />
                <span
                  id="required_span_defendant_email"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Valid Email
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_phone" className="required">
                  Defendant’s Phone:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_phone"
                  name="defendant_phone"
                  onChange={inputChange("defendant_phone")}
                  value={values.defendant_phone}
                />
                <span
                  id="required_span_defendant_phone"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Phone Number
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_street_address" className="required">
                  Street Address:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_street_address"
                  name="defendant_street_address"
                  onChange={inputChange("defendant_street_address")}
                  value={values.defendant_street_address}
                />
                <span
                  id="required_span_defendant_street_address"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Street Address
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_city" className="required">
                  City:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_city"
                  name="defendant_city"
                  onChange={inputChange("defendant_city")}
                  value={values.defendant_city}
                />
                <span
                  id="required_span_defendant_city"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill City
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_state" className="required">
                  State:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_state"
                  name="defendant_state"
                  onChange={inputChange("defendant_state")}
                  value={values.defendant_state}
                />
                <span
                  id="required_span_defendant_state"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill State
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="defendant_zip" className="required">
                  ZIP:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_zip"
                  name="defendant_zip"
                  onChange={inputChange("defendant_zip")}
                  value={values.defendant_zip}
                />
                <span
                  id="required_span_defendant_zip"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Zip
                </span>
              </div>
              <br />
              <div className="form-group">
                <label
                  htmlFor="defendant_county_residence"
                  className="required"
                >
                  County of Residence:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="defendant_county_residence"
                  name="defendant_county_residence"
                  onChange={inputChange("defendant_county_residence")}
                  value={values.defendant_county_residence}
                />
                <span
                  id="required_span_defendant_county_residence"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill County
                </span>
              </div>
              <br />
              <div className="form-group">
                <label
                  htmlFor="defendant_country_residence"
                  className="required"
                >
                  Country of Residence:
                </label>
                <Form.Select
                  aria-label="Defendant’s County Residence"
                  className="form-control"
                  id="defendant_country_residence"
                  name="defendant_country_residence"
                  onChange={inputChange("defendant_country_residence")}
                  value={values.defendant_country_residence}
                >
                  <option value="">Select Country</option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia & Herzegovina">
                    Bosnia & Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">
                    British Indian Ocean Ter
                  </option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Canary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">
                    French Southern Ter
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">
                    Netherland Antilles
                  </option>
                  <option value="Netherlands">
                    Netherlands (Holland, Europe)
                  </option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">
                    Republic of Montenegro
                  </option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre & Miquelon">
                    St Pierre & Miquelon
                  </option>
                  <option value="St Vincent & Grenadines">
                    St Vincent & Grenadines
                  </option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome & Principe">
                    Sao Tome & Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">
                    United Arab Emirates
                  </option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">
                    Virgin Islands (Brit)
                  </option>
                  <option value="Virgin Islands (USA)">
                    Virgin Islands (USA)
                  </option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </Form.Select>
                <span
                  id="required_span_defendant_country_residence"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select Country
                </span>
              </div>
              <br />
              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepSeven;
