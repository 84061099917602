import React, { Component } from "react";
import NoneOfAbove from "../components/Various/NoneOfAbove";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import TopBanner from "../components/TopBanner/TopBanner";
import Footer from "../components/Footer/Footer";

class NoneOfAbovePages extends Component {
  render() {
    return (
      <div>
        <TopNavigation />
        <TopBanner />
        <NoneOfAbove />
        <Footer />
      </div>
    );
  }
}

export default NoneOfAbovePages;
