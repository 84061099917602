import React, {Component, Fragment} from 'react';
import Slider from "react-slick";

class LatestNews extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            autoplay: true,
            
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <div className="news-v1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h2>Latest News</h2>
                                    <p>See All Our Updated and Latest News</p>
                                </div>
                            </div>
                            <Slider {...settings}>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-11.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Insurers toast new discount rate plans</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-10.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Holiday sickness claims farmer stripped of licence</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-9.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">E-filing scheme held up but HMCTS denies any data security risk</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-8.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">'Disgraceful' solicitor overcharged estate by 574%</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-7.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Conveyancing: Home truths</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-6.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Residential property: Don't write off leasehold just yet</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="news-carousel">
                                        <div className="item">
                                            <div className="thumb">
                                                <div className="photo" style={{ backgroundImage:"url('./images/news-5.jpg')" }}>

                                                </div>
                                            </div>
                                            <div className="text">
                                                <h3>
                                                    <a href="">Commercial property: building work</a>
                                                </h3>
                                                <p>Lorem ipsum dolor sit amet, qui case probo velit no, an postea
                                                    scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut
                                                    everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ad
                                                    sint ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LatestNews;