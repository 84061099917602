import React, {Component, Fragment} from 'react';
import Slider from "react-slick";

class Testimonial extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true
        };
        return (
            <Fragment>
                <div className="testimonial-v1">
                    <div className="overlay">

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h2>Testimonial</h2>
                                    <p>Our Happy Clients Tell About Us</p>
                                </div>
                            </div>
                            <Slider {...settings}>
                                <div>
                                    <div className="testimonial-carousel">
                                        <div className="item">
                                            <div className="testimonial-wrapper">
                                                <div className="content">
                                                    <div className="comment">
                                                        <p>
                                                            Ancillae interpretaris ea has. Id amet impedit qui, eripuit
                                                            mnesarchum percipitur in eam. Ne sit habeo inimicus, no his
                                                            liber regione volutpat. Ex quot voluptaria usu, dolor
                                                            vivendo docendi nec ea. Et atqui vocent integre vim, quod
                                                            cibo recusabo ei usu, s
                                                        </p>
                                                        <div className="icon">

                                                        </div>
                                                    </div>
                                                    <div className="author">
                                                        <div className="photo">
                                                            <img src="./images/testimonial-1.jpg" alt="John Doe" />
                                                        </div>
                                                        <div className="text">
                                                            <h3>John Doe</h3>
                                                            <h4>Managing Director
                                                                <span>(ABC Inc.)</span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="testimonial-carousel">
                                        <div className="item">
                                            <div className="testimonial-wrapper">
                                                <div className="content">
                                                    <div className="comment">
                                                        <p>
                                                            Ancillae interpretaris ea has. Id amet impedit qui, eripuit
                                                            mnesarchum percipitur in eam. Ne sit habeo inimicus, no his
                                                            liber regione volutpat. Ex quot voluptaria usu, dolor
                                                            vivendo docendi nec ea. Et atqui vocent integre vim, quod
                                                            cibo recusabo ei usu, s
                                                        </p>
                                                        <div className="icon">

                                                        </div>
                                                    </div>
                                                    <div className="author">
                                                        <div className="photo">
                                                            <img src="./images/testimonial-2.jpg" alt="John Doe" />
                                                        </div>
                                                        <div className="text">
                                                            <h3>John Doe</h3>
                                                            <h4>Managing Director
                                                                <span>(ABC Inc.)</span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Testimonial;