import React, { Component, Fragment } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import DivorceWithoutChildrenForm from "../Forms/DivorceWithoutChildrenForm";
import { NavLink } from "react-router-dom";

class Intake extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <br />
              <br />
              <button
                className="btn orange_button"
                style={{ width: "750px", textAlign: "left" }}
              >
                <Nav.Link>
                  <NavLink to="/IntakeForm" className="navbar_text">
                    Divorce Without Children under the age of 21
                  </NavLink>
                </Nav.Link>
              </button>
              <br />
              <br />
              <button
                className="btn orange_button"
                style={{ width: "750px", textAlign: "left" }}
              >
                <Nav.Link>
                  <NavLink to="" className="navbar_text">
                    Divorce With Children under the age of 21
                  </NavLink>
                </Nav.Link>
              </button>
              <br />
              <br />
              <button
                className="btn orange_button"
                style={{ width: "750px", textAlign: "left" }}
              >
                <Nav.Link>
                  <NavLink to="" className="navbar_text">
                    I have a divorce agreement signed but I need the rest of the
                    divorce package.
                  </NavLink>
                </Nav.Link>
              </button>
              <br />
              <br />
              <button
                className="btn orange_button"
                style={{ width: "750px", textAlign: "left" }}
              >
                <Nav.Link>
                  <NavLink to="" className="navbar_text">
                    I have done an Inquest and now I need a divorce package.
                  </NavLink>
                </Nav.Link>
              </button>
              <br />
              <br />
              <button
                className="btn orange_button"
                style={{ width: "750px", textAlign: "left" }}
              >
                <Nav.Link>
                  <NavLink to="" className="navbar_text">
                    I have received a Letter from the Court requesting
                    corrections.
                  </NavLink>
                </Nav.Link>
              </button>
              <br />
              <br />
              <button
                className="btn orange_button"
                style={{ width: "750px", textAlign: "left" }}
              >
                <Nav.Link>
                  <NavLink to="" className="navbar_text">
                    I do not want to get divorced. I want a separation
                    agreement.
                  </NavLink>
                </Nav.Link>
              </button>
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Intake;
