import React, { Component } from "react";
import { Col } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";


export class DivorceWithoutChildrenFormStepTwo extends Component {
  continue = (e) => {
    var name = document.getElementById("name");
    var email = document.getElementById("email");
    var mobile = document.getElementById("mobile");
    var password = document.getElementById("password");

    var required_span_name = document.getElementById("required_span_name");
    var required_span_email = document.getElementById("required_span_email");
    var required_span_mobile = document.getElementById("required_span_mobile");
    var required_span_password = document.getElementById(
      "required_span_password"
    );

    if (name.value === "") {
      var att = document.createAttribute("required");
      name.setAttributeNode(att);
      required_span_name.style.display = "block";
      cogoToast.error("Please Put Name", { position: "top-center" });
    } else if (email.value === "") {
      var att = document.createAttribute("required");
      email.setAttributeNode(att);
      required_span_name.style.display = "none";
      required_span_email.style.display = "block";
      cogoToast.error("Please Put Email", { position: "top-center" });
    } else if (mobile.value === "") {
      var att = document.createAttribute("required");
      mobile.setAttributeNode(att);
      required_span_email.style.display = "none";
      required_span_mobile.style.display = "block";
      cogoToast.error("Please Put Mobile", { position: "top-center" });
    } else if (password.value === "") {
      var att = document.createAttribute("required");
      password.setAttributeNode(att);
      required_span_mobile.style.display = "none";
      required_span_password.style.display = "block";
      cogoToast.error("Please Put Password", { position: "top-center" });
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8} sm={12}>
            <div className="container">
              <h4 className="mb-5">Account Information</h4>

              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={inputChange("name")}
                  value={values.name}
                />
                <span
                  id="required_span_name"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Name
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={inputChange("email")}
                  value={values.email}
                />
                <span
                  id="required_span_email"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Email
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="phone">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  name="mobile"
                  onChange={inputChange("mobile")}
                  value={values.mobile}
                />
                <span
                  id="required_span_mobile"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Mobile
                </span>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={inputChange("password")}
                  value={values.password}
                />
                <span
                  id="required_span_password"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Fill Password
                </span>
              </div>

              <br />
              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next
                  </button>
                  <button className="btn orange_button flex-column">
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepTwo;
