import React, { Component, Fragment } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepThree extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  continue = (e) => {
    var plaintiff_first_name = document.getElementById("plaintiff_first_name");
    var plaintiff_last_name = document.getElementById("plaintiff_last_name");
    var plaintiff_email = document.getElementById("plaintiff_email");
    var plaintiff_phone = document.getElementById("plaintiff_phone");
    var plaintiff_street_address = document.getElementById(
      "plaintiff_street_address"
    );
    var plaintiff_city = document.getElementById("plaintiff_city");
    var plaintiff_state = document.getElementById("plaintiff_state");
    var plaintiff_zip = document.getElementById("plaintiff_zip");
    var plaintiff_county = document.getElementById("plaintiff_county");
    var plaintiff_country = document.getElementById("plaintiff_country");

    var required_span_plaintiff_first_name = document.getElementById(
      "required_span_plaintiff_first_name"
    );
    var required_span_plaintiff_last_name = document.getElementById(
      "required_span_plaintiff_last_name"
    );
    var required_span_plaintiff_email = document.getElementById(
      "required_span_plaintiff_email"
    );
    var required_span_plaintiff_phone = document.getElementById(
      "required_span_plaintiff_phone"
    );
    var required_span_plaintiff_street_address = document.getElementById(
      "required_span_plaintiff_street_address"
    );
    var required_span_plaintiff_city = document.getElementById(
      "required_span_plaintiff_city"
    );
    var required_span_plaintiff_state = document.getElementById(
      "required_span_plaintiff_state"
    );
    var required_span_plaintiff_zip = document.getElementById(
      "required_span_plaintiff_zip"
    );
    var required_span_plaintiff_county = document.getElementById(
      "required_span_plaintiff_county"
    );
    var required_span_plaintiff_country = document.getElementById(
      "required_span_plaintiff_country"
    );

    if (plaintiff_first_name.value === "") {
      var att = document.createAttribute("required");
      plaintiff_first_name.setAttributeNode(att);
      required_span_plaintiff_first_name.style.display = "block";
      cogoToast.error("Please Put Plaintiff First Name", {
        position: "top-center",
      });
    } else if (plaintiff_last_name.value === "") {
      var att = document.createAttribute("required");
      plaintiff_last_name.setAttributeNode(att);
      required_span_plaintiff_first_name.style.display = "none";
      required_span_plaintiff_last_name.style.display = "block";
      cogoToast.error("Please Put Plaintiff Last Name", {
        position: "top-center",
      });
    } else if (plaintiff_email.value === "") {
      var att = document.createAttribute("required");
      plaintiff_email.setAttributeNode(att);
      required_span_plaintiff_last_name.style.display = "none";
      required_span_plaintiff_email.style.display = "block";
      cogoToast.error("Please Put Plaintiff Email", { position: "top-center" });
    } else if (plaintiff_phone.value === "") {
      var att = document.createAttribute("required");
      plaintiff_phone.setAttributeNode(att);
      required_span_plaintiff_email.style.display = "none";
      required_span_plaintiff_phone.style.display = "block";
      cogoToast.error("Please Put A Phone Number ", { position: "top-center" });
    } else if (plaintiff_street_address.value === "") {
      var att = document.createAttribute("required");
      plaintiff_street_address.setAttributeNode(att);
      required_span_plaintiff_phone.style.display = "none";
      required_span_plaintiff_street_address.style.display = "block";
      cogoToast.error("Please Put A Street Address", {
        position: "top-center",
      });
    } else if (plaintiff_city.value === "") {
      var att = document.createAttribute("required");
      plaintiff_city.setAttributeNode(att);
      required_span_plaintiff_street_address.style.display = "none";
      required_span_plaintiff_city.style.display = "block";
      cogoToast.error("Please Put A City", { position: "top-center" });
    } else if (plaintiff_state.value === "") {
      var att = document.createAttribute("required");
      plaintiff_state.setAttributeNode(att);
      required_span_plaintiff_city.style.display = "none";
      required_span_plaintiff_state.style.display = "block";
      cogoToast.error("Please Put A State", { position: "top-center" });
    } else if (plaintiff_zip.value === "") {
      var att = document.createAttribute("required");
      plaintiff_zip.setAttributeNode(att);
      required_span_plaintiff_state.style.display = "none";
      required_span_plaintiff_zip.style.display = "block";
      cogoToast.error("Please Put A Zip", { position: "top-center" });
    } else if (plaintiff_county.value === "") {
      var att = document.createAttribute("required");
      plaintiff_county.setAttributeNode(att);
      required_span_plaintiff_zip.style.display = "none";
      required_span_plaintiff_county.style.display = "block";
      cogoToast.error("Please Put A County", { position: "top-center" });
    } else if (plaintiff_country.value === "") {
      var att = document.createAttribute("required");
      plaintiff_country.setAttributeNode(att);
      required_span_plaintiff_county.style.display = "none";
      required_span_plaintiff_country.style.display = "block";
      cogoToast.error("Please Put A Country", { position: "top-center" });
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const { values, inputChange } = this.props;
    return (
      <Fragment className="container mt-lg-5 mb-5">
        <Container>
          <Row>
            <Col lg={3} md={2}></Col>
            <Col lg={6} md={8} sm={12}>
              <div className="container mt-lg-5 mb-5">
                <h4 className="mb-5">INFORMATION ABOUT THE PLAINTIFF:</h4>
                <p>
                  <b>Security & Privacy:</b> This is a secured website. We keep
                  your personal information confidential. We do not share your
                  information with third parties.
                </p>
                <p>
                  <b>Explanation:</b> Plaintiff is the person who starts the
                  divorce. It does not matter who is the Plaintiff in a divorce
                  action.
                </p>
                <p>
                  <b>Explanation:</b> You cannot use a PO BOX address.
                </p>
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "RED",
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "RED",
                    }}
                  >
                    Required
                  </span>
                </p>

                <br />
                <Form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor="plaintiff_first_name" className="required">
                      Plaintiff’s First Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_first_name"
                      name="plaintiff_first_name"
                      onChange={inputChange("plaintiff_first_name")}
                      value={values.plaintiff_first_name}
                    />
                    <span
                      id="required_span_plaintiff_first_name"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill First Name
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_last_name" className="required">
                      Plaintiff’s Last Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_last_name"
                      name="plaintiff_last_name"
                      onChange={inputChange("plaintiff_last_name")}
                      value={values.plaintiff_last_name}
                    />
                    <span
                      id="required_span_plaintiff_last_name"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill Last Name
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_maiden_name">
                      Plaintiff’s Prior Surname (maiden name):
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_maiden_name"
                      name="plaintiff_maiden_name"
                      onChange={inputChange("plaintiff_maiden_name")}
                      value={values.plaintiff_maiden_name}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_email" className="required">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="plaintiff_email"
                      name="plaintiff_email"
                      onChange={inputChange("plaintiff_email")}
                      value={values.plaintiff_email}
                    />
                    <span
                      id="required_span_plaintiff_email"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill Valid Email
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_phone" className="required">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_phone"
                      name="plaintiff_phone"
                      onChange={inputChange("plaintiff_phone")}
                      value={values.plaintiff_phone}
                    />
                    <span
                      id="required_span_plaintiff_phone"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill Mobile Number
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label
                      htmlFor="plaintiff_street_address"
                      className="required"
                    >
                      Street Address:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_street_address"
                      name="plaintiff_street_address"
                      onChange={inputChange("plaintiff_street_address")}
                      value={values.plaintiff_street_address}
                    />
                    <span
                      id="required_span_plaintiff_street_address"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill Street Address
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_city" className="required">
                      City:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_city"
                      name="plaintiff_city"
                      onChange={inputChange("plaintiff_city")}
                      value={values.plaintiff_city}
                    />
                    <span
                      id="required_span_plaintiff_city"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill City
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_state" className="required">
                      State:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_state"
                      name="plaintiff_state"
                      onChange={inputChange("plaintiff_state")}
                      value={values.plaintiff_state}
                    />
                    <span
                      id="required_span_plaintiff_state"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill State
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_zip" className="required">
                      ZIP:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_zip"
                      name="plaintiff_zip"
                      onChange={inputChange("plaintiff_zip")}
                      value={values.plaintiff_zip}
                    />
                    <span
                      id="required_span_plaintiff_zip"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill Zip
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_county" className="required">
                      County of Residence:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff_county"
                      name="plaintiff_county"
                      onChange={inputChange("plaintiff_county")}
                      value={values.plaintiff_county}
                    />
                    <span
                      id="required_span_plaintiff_county"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Fill County
                    </span>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="plaintiff_country" className="required">
                      Country of Residence:
                    </label>
                    <Form.Select
                      aria-label="Plaintiff’s Country"
                      className="form-control"
                      id="plaintiff_country"
                      name="plaintiff_country"
                      onChange={inputChange("plaintiff_country")}
                      value={values.plaintiff_country}
                    >
                      <option value="">Select Country</option>
                      <option value="Afganistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antigua & Barbuda">
                        Antigua & Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bonaire">Bonaire</option>
                      <option value="Bosnia & Herzegovina">
                        Bosnia & Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Ter">
                        British Indian Ocean Ter
                      </option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Canary Islands">Canary Islands</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Channel Islands">Channel Islands</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos Island">Cocos Island</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote DIvoire">Cote DIvoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curaco">Curacao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="East Timor">East Timor</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands">Falkland Islands</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Ter">
                        French Southern Ter
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Great Britain">Great Britain</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="India">India</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea North">Korea North</option>
                      <option value="Korea Sout">Korea South</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedonia">Macedonia</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Midway Islands">Midway Islands</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Nambia">Nambia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherland Antilles">
                        Netherland Antilles
                      </option>
                      <option value="Netherlands">
                        Netherlands (Holland, Europe)
                      </option>
                      <option value="Nevis">Nevis</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau Island">Palau Island</option>
                      <option value="Palestine">Palestine</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Phillipines">Philippines</option>
                      <option value="Pitcairn Island">Pitcairn Island</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Republic of Montenegro">
                        Republic of Montenegro
                      </option>
                      <option value="Republic of Serbia">
                        Republic of Serbia
                      </option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="St Barthelemy">St Barthelemy</option>
                      <option value="St Eustatius">St Eustatius</option>
                      <option value="St Helena">St Helena</option>
                      <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                      <option value="St Lucia">St Lucia</option>
                      <option value="St Maarten">St Maarten</option>
                      <option value="St Pierre & Miquelon">
                        St Pierre & Miquelon
                      </option>
                      <option value="St Vincent & Grenadines">
                        St Vincent & Grenadines
                      </option>
                      <option value="Saipan">Saipan</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Samoa American">Samoa American</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome & Principe">
                        Sao Tome & Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Tahiti">Tahiti</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad & Tobago">
                        Trinidad & Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks & Caicos Is">
                        Turks & Caicos Is
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Erimates">
                        United Arab Emirates
                      </option>
                      <option value="United States of America">
                        United States of America
                      </option>
                      <option value="Uraguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City State">
                        Vatican City State
                      </option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands (Brit)">
                        Virgin Islands (Brit)
                      </option>
                      <option value="Virgin Islands (USA)">
                        Virgin Islands (USA)
                      </option>
                      <option value="Wake Island">Wake Island</option>
                      <option value="Wallis & Futana Is">
                        Wallis & Futana Is
                      </option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zaire">Zaire</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </Form.Select>
                    <span
                      id="required_span_plaintiff_country"
                      style={{ display: "none", color: "RED" }}
                    >
                      Please Select Country
                    </span>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn orange_button flex-column me-2"
                        onClick={this.back}
                      >
                        Go Back
                      </button>
                      <button
                        className="btn orange_button flex-column me-2"
                        onClick={this.continue}
                      >
                        Next
                      </button>
                      <button className="btn orange_button flex-column">
                        Save Intake
                      </button>
                    </div>
                  </div>
                  <br /> <br />
                </Form>
              </div>

            </Col>
            <Col lg={3} md={2}></Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default DivorceWithoutChildrenFormStepThree;
