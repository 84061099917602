import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import * as cogoToast from "react-bootstrap-sweetalert";

class DivorceWithoutChildrenFormStepEleven extends Component {
  continue = (e) => {
    if (
      document.getElementById("complete_assets_debts_workbook_yes_no").value ===
      ""
    ) {
      var att = document.createAttribute("required");
      document
        .getElementById("complete_assets_debts_workbook_yes_no")
        .setAttributeNode(att);
      document.getElementById(
        "required_span_complete_assets_debts_workbook_yes_no"
      ).style.display = "block";
      cogoToast.error("Please Select ASSETS OR DEBTS to be divided", {
        position: "top-center",
      });
    } else if (document.getElementById("want_settlement_yes_no").value === "") {
      var att = document.createAttribute("required");
      document.getElementById("want_settlement_yes_no").setAttributeNode(att);
      document.getElementById(
        "required_span_complete_assets_debts_workbook_yes_no"
      ).style.display = "none";
      document.getElementById("want_settlement_yes_no").style.display = "block";
      cogoToast.error(
        "Please Select Do you want a Stipulation of Settlement (Divorce Agreement) to be prepared?",
        { position: "top-center" }
      );
    } else if (
      document.getElementById("already_separation_yes_no").value === ""
    ) {
      var att = document.createAttribute("required");
      document
        .getElementById("already_separation_yes_no")
        .setAttributeNode(att);
      document.getElementById(
        "required_span_want_settlement_divorce_agreement_prepared_yes_no"
      ).style.display = "none";
      document.getElementById(
        "required_span_already_have_separation_agreement_stipulation_yes_no"
      ).style.display = "block";
      cogoToast.error(
        "Please Select Do you already have a separation agreement / stipulation?",
        { position: "top-center" }
      );
    } else {
      e.preventDefault();
      this.props.nextStep();
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  showUploadButton() {
    let show_upload_button_checkBox = document.getElementById(
      "already_have_separation_agreement_stipulation_yes"
    );
    let file_upload_button = document.getElementById("upload_button");
    if (show_upload_button_checkBox.checked == true) {
      file_upload_button.style.display = "block";
    } else if (show_upload_button_checkBox.checked == false) {
      file_upload_button.style.display = "none";
    }
  }
  hideUploadButton() {
    let hide_upload_button_checkBox = document.getElementById(
      "already_have_separation_agreement_stipulation_yes"
    );
    let file_upload_button = document.getElementById("upload_button");
    if (hide_upload_button_checkBox.checked == false) {
      file_upload_button.style.display = "none";
    }
  }

  render() {
    const { values, inputChange } = this.props;
    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={1} md={1}></Col>
          <Col lg={10} md={10} sm={12}>
            <div className="container">
              <h4 className="mb-5">Assets or Debts:</h4>
              <h6>MUST READ THE WARNING & EXPLANATION</h6>
              <p style={{ textAlign: "justify" }}>
                <b>Warning & Explanation:</b> If you have assets e.g. a house, a
                condo, a car, savings in the bank, investment accounts,
                retirement accounts etc., you should protect them by having your
                spouse sign a Stipulation of Settlement (Divorce Agreement) in
                which your spouse would waive his or her claims against your
                assets. If your spouse waives and releases his or her claims
                against your assets by signing a Stipulation of Settlement
                before a Notary Public, he or she will lose his or her right to
                your assets. If your spouse does not sign a Stipulation of
                Settlement, your assets will have less protection. Your spouse
                can later try to reopen your divorce and claim that he or she
                did not know of your assets and that he or she now wants portion
                of your assets. It is harder for your spouse to revoke/cancel a
                Stipulation of Settlement, which clearly lists and discloses
                your assets to your spouse, than it is to reopen the divorce
                when there is no Stipulation signed by your spouse and your
                spouse can claim that he or she was never informed of your
                assets.
              </p>
              <p style={{ textAlign: "justify" }}>
                <b>Explanation:</b> You can upload the Separation Agreement /
                Stipulation of Settlement (divorce agreement) now or at the end
                of the intake.
              </p>
              <p style={{ textAlign: "justify" }}>
                <b>Explanation:</b> What if you have a prenuptial agreement or
                postnuptial agreement?
              </p>
              <p style={{ textAlign: "justify" }}>
                Please upload it. However, in New York, prenuptial and
                postnuptial agreements cannot establish child support or
                custody/visitation. These issues must be resolved through a
                divorce agreement as part of your divorce case. You may
                incorporate the terms of your prenuptial or postnuptial
                agreement into your new Stipulation of Settlement (divorce
                agreement) by completing this online intake in accordance with
                the provisions of your prenuptial or postnuptial agreement
              </p>
              <p style={{ textAlign: "justify" }}>
                <b>Warning:</b> Your divorce agreement must be signed after your
                divorce case has started.
              </p>
              <p>
                <span
                  style={{ fontWeight: "bold", fontSize: "20px", color: "RED" }}
                >
                  *
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px", color: "RED" }}
                >
                  {" "}
                  Required
                </span>
              </p>

              <div className="form-group">
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="complete_assets_debts_workbook_yes_no"
                  name="complete_assets_debts_workbook_yes_no"
                  value={values.complete_assets_debts_workbook_yes_no}
                />{" "}
                <br />
                <Form.Label className="required">
                  Are there any ASSETS OR DEBTS to be divided?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Yes"
                  id="complete_assets_debts_workbook_yes"
                  name="complete_assets_debts_workbook_yes_no"
                  label="Yes"
                  onChange={inputChange(
                    "complete_assets_debts_workbook_yes_no"
                  )}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="No"
                  id="complete_assets_debts_workbook_no"
                  name="complete_assets_debts_workbook_yes_no"
                  label="No"
                  onChange={inputChange(
                    "complete_assets_debts_workbook_yes_no"
                  )}
                />
                <span
                  id="required_span_complete_assets_debts_workbook_yes_no"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select ASSETS OR DEBTS to be divided
                </span>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="want_settlement_yes_no"
                  name="want_settlement_yes_no"
                  value={values.want_settlement_yes_no}
                />{" "}
                <br />
                <Form.Label className="required">
                  Do you want a Stipulation of Settlement (Divorce Agreement) to
                  be prepared?
                </Form.Label>
                <br />
                <Form.Text className="text-muted">
                  If YES, you must complete the Assets & Debts Workbook!
                </Form.Text>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Yes"
                  id="want_settlement_divorce_agreement_prepared_yes"
                  name="want_settlement_yes_no"
                  label="Yes"
                  onChange={inputChange("want_settlement_yes_no")}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="No"
                  id="want_settlement_divorce_agreement_prepared_no"
                  name="want_settlement_yes_no"
                  label="No"
                  onChange={inputChange("want_settlement_yes_no")}
                />
                <span
                  id="required_span_want_settlement_divorce_agreement_prepared_yes_no"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select Do you want a Stipulation of Settlement (Divorce
                  Agreement) to be prepared?
                </span>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="hidden"
                  className="radio-buttons"
                  id="already_separation_yes_no"
                  name="already_separation_yes_no"
                  value={values.already_separation_yes_no}
                />{" "}
                <br />
                <Form.Label className="required">
                  Do you already have a separation agreement / stipulation?
                </Form.Label>
                <br />
                <Form.Text className="text-muted">
                  If YES, you must attach a signed copy of your agreement!
                </Form.Text>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  value="Yes"
                  id="already_have_separation_agreement_stipulation_yes"
                  name="already_separation_yes_no"
                  label="Yes"
                  onChange={inputChange("already_separation_yes_no")}
                  onClick={this.showUploadButton}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="No"
                  id="already_have_separation_agreement_stipulation_no"
                  name="already_separation_yes_no"
                  label="No"
                  onChange={inputChange("already_separation_yes_no")}
                  onClick={this.hideUploadButton}
                />
                <span
                  id="required_span_already_have_separation_agreement_stipulation_yes_no"
                  style={{ display: "none", color: "RED" }}
                >
                  Please Select Do you already have a separation agreement /
                  stipulation?
                </span>
                <input
                  ref="fileInput"
                  onChange={this.handleFileUpload}
                  type="file"
                  style={{ display: "none" }}
                  // multiple={false}
                />
                <button
                  id="upload_button"
                  style={{ display: "none" }}
                  className="btn orange_button"
                  onClick={() => this.refs.fileInput.click()}
                >
                  Upload File
                </button>
              </div>

              <br />

              <br />

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.back}
                  >
                    Go Back
                  </button>
                  <button
                    className="btn orange_button flex-column me-2"
                    onClick={this.continue}
                  >
                    Next Page
                  </button>
                  <button
                    className="btn orange_button flex-column"
                    onClick={this.continue}
                  >
                    Save Intake
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={1} md={1}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepEleven;
