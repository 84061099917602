import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="footer_section">
          <Container fluid={false} className="footer_inner_section">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <p>
                  <strong>Disclaimer:</strong> Getdivorce101.com is not a law
                  firm and its services, website, forms or templates are not a
                  substitute for the advice or services of an attorney.
                  Getdivorce101.com provides access to computer-aided self-help
                  services at your specific direction. Getdivorce101.com’s
                  website and written instructions provide general information
                  about the divorce process only; we cannot give you any
                  specific advice, opinions or recommendations as to your
                  selection or completion of forms or your particular legal
                  rights, remedies or options. Getdivorce101.com is a website
                  that provides access to self-guided online questionnaires.
                  Getdivorce101.com does not sell blank forms. You may be able
                  to download blank forms from a government website depending on
                  your state.
                </p>
                <p>
                  Communications between you and Getdivorce101.com are governed
                  by our <a href="#">Privacy Policy</a> but are not covered by
                  the attorney-client or work product privileges. Your access to
                  Getdivorce101.com’s website is subject to and governed by our{" "}
                  <a href="#">Terms Of Use</a>. Any purchase from
                  Getdivorce101.com is subject to and governed by our{" "}
                  <a href="#">Terms of Service</a>.
                </p>
                <p>
                  Your access to the website is subject to our{" "}
                  <a href="#">Terms Of Use</a>.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Fragment>
    );
  }
}

export default Footer;
