import React, { Component } from "react";
import TopNavigation from "../TopNavigation/TopNavigation";
import TopBanner from "../TopBanner/TopBanner";
import Footer from "../Footer/Footer";
import Services from "../Services/Services";
import Attorneys from "../Attorneys/Attorneys";
import Testimonial from "../Testimonial/Testimonial";
import LatestNews from "../LatestNews/LatestNews";
import OurPartners from "../OurPartners/OurPartners";
import FooterSocial from "../Footer/FooterSocial";
import FooterMain from "../Footer/FooterMain";

class Home extends Component {
  render() {
    return (
      <div>
        <TopNavigation />
        <TopBanner />
        <Services/>
        <Attorneys/>
        <Testimonial/>
        <LatestNews/>
        <OurPartners/>
        <FooterSocial/>
        <FooterMain/>
        <br />
        <br />
        <br />
        <h2 style={{ textAlign: "center" }}>Home Page</h2>
        <h3 style={{ textAlign: "center" }}>Under Construction</h3>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Home;
