import React, {Component, Fragment} from 'react';
import "../../assets/css/custom_style.css";
import "../../assets/css/font-awesome.min.css";


class FooterMain extends Component {
    render() {
        return (
            <Fragment>
                <section className="footer-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-3 col-lg-3 footer-col">
                                <h3>About Us</h3>
                                <p>
                                    <p>Lorem ipsum dolor sit amet, omnis signiferumque in mei, mei ex enim
                                        concludaturque. Senserit salutandi euripidis no per, modus maiestatis scribentur
                                        est an.�Ea suas pertinax has, solet officiis pericula cu pro, possit inermis qui
                                        ad. An mea tale perfecto sententiae, eos inani epicuri concludaturque ex.</p>
                                    <br/>
                                </p>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 footer-col">
                                <h3>Latest News</h3>
                                <div className="news-item">
                                    <div className="news-title"><a
                                        href="">Insurers
                                        toast new discount rate plans</a></div>
                                </div>
                                <div className="news-item">
                                    <div className="news-title"><a
                                        href="">Holiday
                                        sickness claims farmer stripped of licence</a></div>
                                </div>
                                <div className="news-item">
                                    <div className="news-title"><a
                                        href="">E-filing
                                        scheme held up but HMCTS denies any data security risk</a></div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 footer-col">
                                <h3>Popular News</h3>
                                <div className="news-item">
                                    <div className="news-title"><a
                                        href="">Residential
                                        property: Don't write off leasehold just yet</a></div>
                                </div>
                                <div className="news-item">
                                    <div className="news-title"><a
                                        href="">'Disgraceful'
                                        solicitor overcharged estate by 574%</a></div>
                                </div>
                                <div className="news-item">
                                    <div className="news-title"><a
                                        href="">Legal
                                        clerk jailed after stealing £300,000 from firm</a></div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 footer-col">
                                <h3>Contact Us</h3>
                                <div className="contact-item">
                                    <div className="icon"><i className="fa fa-map-marker"></i></div>
                                    <div className="text">ABC Steet, NewYork.</div>
                                </div>
                                <div className="contact-item">
                                    <div className="icon"><i className="fa fa-phone"></i></div>
                                    <div className="text">123-456-7878</div>
                                </div>
                                <div className="contact-item">
                                    <div className="icon"><i className="fa fa-fax"></i></div>
                                    <div className="text">123-456-7890</div>
                                </div>
                                <div className="contact-item">
                                    <div className="icon"><i className="fa fa-envelope-o"></i></div>
                                    <div className="text">info@getdivorce101.com</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default FooterMain;