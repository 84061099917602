import React, { Component, Fragment } from "react";
import { Container } from "react-bootstrap";
import "core-js/es/reflect";

import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel, CarouselProps as onClickThumb, CarouselProps as onClickItem} from 'react-responsive-carousel';

import "../../assets/css/custom_style.css";

//import "../../assets/css/slick.min.css";
//import "../../assets/css/slick-theme.min.css";

import Arrow from 'react-arrows';




import Slider from "react-slick";



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, display: "block",  }}
          onClick={onClick}
      />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, display: "block" }}
          onClick={onClick}
      />
  );
}



export default class TopBanner extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrow: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      leftArrow: true,
      rightArrow: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      autoplay: true,
    };
    return (
      <Fragment>

        {/*<Container className="topFixedBanner" fluid={true}>*/}

        {/*</Container>*/}

        <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            style={{  left: "200px", height: "50px", width: "50px", }}
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
            rel="stylesheet"
            type="text/css"
            style={{  right: "200px", height: "50px", width: "50px", }}
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />


        <div>
            <Slider {...settings}>
                <div className="image_content">
                    <img className="w-100" src="./images/slider-1.jpg" />
                    <div className="text-block">
                        <h2>
                            Legal Advice for You
                        </h2>

                        <h3>
                            We provide the best solution to clients
                        </h3>

                        <p>
                            Lorem ipsum dolor sit amet, ad vim indoctum maluisset. <br/>
                            Posse philosophia id sed, qui ut saepe nonumes.
                        </p>

                        <p className="text-block-button">
                            <a href="#" className="btn btn-flat">Read More</a>
                        </p>
                    </div>
                </div>
                <div className="image_content">
                    <img className="w-100" src="./images/slider-2.jpg" />
                    <div className="text-block">
                        <h2>
                            Legal Advice for You
                        </h2>

                        <h3>
                            We provide the best solution to clients
                        </h3>

                        <p>
                            Lorem ipsum dolor sit amet, ad vim indoctum maluisset. <br/>
                            Posse philosophia id sed, qui ut saepe nonumes.
                        </p>

                        <p className="text-block-button">
                            <a href="#" className="btn btn-flat">Read More</a>
                        </p>
                    </div>
                </div>
            </Slider>
        </div>


      </Fragment>
    );
  }
}



// export default TopBanner;
