import React, {Component, Fragment} from 'react';
import Slider from "react-slick";

class OurPartners extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <div className="partner-v1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h2>Our Partners</h2>
                                    <p>All Our Company Partners are Listed Below</p>
                                </div>
                            </div>
                            <Slider {...settings}>
                                <div>
                                    <div className="partner-carousel">
                                        <div className="item">
                                            <div className="inner">
                                                <img src="./images/partner-1.png" alt="Company 1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="partner-carousel">
                                        <div className="item">
                                            <div className="inner">
                                                <img src="./images/partner-2.png" alt="Company 2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="partner-carousel">
                                        <div className="item">
                                            <div className="inner">
                                                <img src="./images/partner-3.png" alt="Company 3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="partner-carousel">
                                        <div className="item">
                                            <div className="inner">
                                                <img src="./images/partner-4.png" alt="Company 4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="partner-carousel">
                                        <div className="item">
                                            <div className="inner">
                                                <img src="./images/partner-5.png" alt="Company 5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="partner-carousel">
                                        <div className="item">
                                            <div className="inner">
                                                <img src="./images/partner-6.png" alt="Company 6" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default OurPartners;