import React, { Component } from "react";
import Intake from "../components/Intake/Intake";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import TopBanner from "../components/TopBanner/TopBanner";
import Footer from "../components/Footer/Footer";

class IntakePage extends Component {
  render() {
    return (
      <div>
        <TopNavigation />
        <TopBanner />
        <Intake />
        <Footer />
      </div>
    );
  }
}

export default IntakePage;
