import React, { Component, useState } from "react";
import { Accordion, Button, Col, Form, Nav } from "react-bootstrap";
import { NavLink, Route, Routes } from "react-router-dom";
import checked from "react-bootstrap/Form";
import NoneOfAbovePages from "../../router/AppRoute";
import { Checkbox } from "react-inputs-validation";
import * as cogoToast from "react-bootstrap-sweetalert";


function checkAddress() {
  var chkBox = document.getElementById("checkAddress");
  if (chkBox.checked) {
    return (window.location.href = "https://getdivorce101.com/#/none-of-above");
  }
}

class DivorceWithoutChildrenFormStepOne extends Component {
  state = {
    checked: true,
    setChecked: true,
    isChecked: true,
  };

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  componentDidMount() {
    window.scroll(0, 300);
  }

  render() {
    const {
      values,
      inputChange,
      inputChangeGroup1,
      inputChangeGroup2,
      inputChangeGroup3,
      inputChangeGroup4,
      inputChangeGroup5,
    } = this.props;

    return (
      <div className="container mt-lg-5 mb-5">
        <div className="row">
          <Col lg={2} md={2}></Col>
          <Col lg={8} md={8} sm={12}>
            <input
              type="hidden"
              id="form_prefix"
              name="form_prefix"
              onChange={inputChange("form_prefix")}
              value={values.form_prefix}
            />
            <input
              type="hidden"
              id="form_name"
              name="form_name"
              onChange={inputChange("form_name")}
              value={values.form_name}
            />

            <h2>Divorce Without Children</h2>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h4>Residency Requirements</h4>
              <p>
                Are you eligible to file a divorce in New York? (You do not need
                to be a resident in New York as long as your spouse still lives
                in New York){" "}
              </p>

              <Form.Label>
                <strong>PLEASE CHOOSE ONE RESIDENCY OPTION BELOW:</strong>
              </Form.Label>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Either you or your spouse has been a resident of New York State for a continuous period of at least two years immediately preceding the commencement of this divorce action?"
                    id="group_one"
                    name="group_one"
                    type={type}
                    defaultChecked={this.state.isChecked}
                    onChange={inputChangeGroup1("group_one")}
                    value={values.group_one}
                    checked={values.group_one === false ? "" : checked}
                  />
                  <Form.Check
                    inline
                    label="You were married in New York and either you or your spouse is a resident of New York State and has been for a continuous period of one year immediately preceding the commencement of this divorce action."
                    id="group_two"
                    name="group_two"
                    type={type}
                    defaultChecked={this.state.isChecked}
                    onChange={inputChangeGroup2("group_two")}
                    value={values.group_two}
                    checked={values.group_two === false ? "" : checked}
                  />
                  <Form.Check
                    inline
                    label="You and your spouse have resided in New York State as husband and wife and either you or your spouse is a resident of New York State and has been for a continuous period of one year immediately preceding the commencement of this divorce action."
                    id="group_three"
                    name="group_three"
                    type={type}
                    defaultChecked={this.state.isChecked}
                    onChange={inputChangeGroup3("group_three")}
                    value={values.group_three}
                    checked={values.group_three === false ? "" : checked}
                  />
                  <Form.Check
                    inline
                    label="The cause for divorce occurred in New York State and either you or your spouse is a resident of NY State and has been for a continuous period of one year immediately preceding the commencement of this divorce action."
                    id="group_four"
                    name="group_four"
                    type={type}
                    defaultChecked={this.state.isChecked}
                    onChange={inputChangeGroup4("group_four")}
                    value={values.group_four}
                    checked={values.group_four === false ? "" : checked}
                  />
                  <Form.Check
                    inline
                    label="The cause for divorce occurred in New York and you and your spouse are residents of New York at the time of commencement of this divorce action."
                    id="group_five"
                    name="group_five"
                    type={type}
                    defaultChecked={this.state.isChecked}
                    onChange={inputChangeGroup5("group_five")}
                    value={values.group_five}
                    checked={values.group_five === false ? "" : checked}
                  />
                  <input
                    id="checkAddress"
                    name="checkAddress"
                    onChange={checkAddress}
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="none_of_aboce" className="ps-2">
                    None of Above
                  </label>
                </div>
              ))}
              <br />
            </Form.Group>

            <div className="text-right">
              <button className="btn orange_button" onClick={this.continue}>
                Next
              </button>
            </div>
          </Col>
          <Col lg={2} md={2}></Col>
        </div>
      </div>
    );
  }
}

export default DivorceWithoutChildrenFormStepOne;
